/**
 * Code is basically copied from https://mui.com/material-ui/react-drawer/#persistent-drawer
 *
 * There is very little changes from the original code from the tutorial.
 */

import * as React from "react";
import {
  styled,
  useTheme,
  Theme,
  CSSObject,
  makeStyles,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import ManageBulkGroupsIcon from "../../../assets/images/ManageBulkGroupsIcon.svg";
import ManageBulkGroupsIconActive from "../../../assets/images/ManageBulkGroupsIconActive.svg";

import VendorEventsIcons from "../../../assets/images/VendorEventsIcons.svg";
import VendorEventsIconsActive from "../../../assets/images/VendorEventsIconsActive.svg";
import ManageWalkthroughIcons from "../../../assets/images/ManageWalkthroughIcons.svg";
import ManageWalkthroughIconsActive from "../../../assets/images/ManageWalkthroughIconsActive.svg";
import ManageFilesIcons from "../../../assets/images/ManageFilesIcons.svg";
import ManageFilesIconsActive from "../../../assets/images/ManageFilesIconsActive.svg";
import ManageUsersInactive from "../../../assets/images/ManageUsersInactive.svg";
import ManageUsersActive from "../../../assets/images/ManageUsersActive.svg";
import ManageListingsIcons from "../../../assets/images/ManageListingsIcons.svg";
import ManageListingsIconsActive from "../../../assets/images/ManageListingsIconsActive.svg";
import ManageTitlesIcons from "../../../assets/images/ManageTitlesIcons.svg";
import ManageTitlesIconsActive from "../../../assets/images/ManageTitlesIconsActive.svg";
import ChangePasswordactive from "../../../assets/images/ChangePasswordactive.svg";
import ChangePasswordActive from "../../../assets/images/ChangePassword_Active.svg";

import GenerateDocumentsactive from "../../../assets/images/GenerateDocumentsactive.svg";
import GenerateDocumentsActive from "../../../assets/images/GenerateDocuments_Active.svg";

import VendorZipCodesIcon from "../../../assets/images/VendorZipCodesIcon.svg";
import VendorZipCodesIconActive from "../../../assets/images/VendorZipCodesIconActive.svg";
import BulkAddListingsIcon from "../../../assets/images/BulkAddListingsIcon.svg";
import BulkAddListingsIconActive from "../../../assets/images/BulkAddListingsIconActive.svg";
import MessagesIcon from "../../../assets/images/MessagesIcon.svg";
import MessagesIconActive from "../../../assets/images/MessagesIconActive.svg";
import MoneyReciveIcon from "../../../assets/images/MoneyReciveIcon.svg";
import MoneyReciveIconActive from "../../../assets/images/MoneyReciveIconActive.svg";

import ChevronLeftIcon from ".././../../assets/images/ChevronLeftIcon.svg";
import ChevronRightIcon from ".././../../assets/images/ChevronRightIcon.svg";

import logo from ".././../../assets/images/logo.svg";
import logoOpen from ".././../../assets/images/logoOpen.svg";
import LogoutActiveIcon from "../../../assets/images/logoutActiveIcon.svg";

import OtherHousesTwoToneIcon from "@mui/icons-material/OtherHousesTwoTone";
import HolidayVillageTwoToneIcon from "@mui/icons-material/HolidayVillageTwoTone";
import WorkspacesTwoToneIcon from "@mui/icons-material/WorkspacesTwoTone";
import ContentPasteTwoToneIcon from "@mui/icons-material/ContentPasteTwoTone";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import SettingsApplicationsTwoToneIcon from "@mui/icons-material/SettingsApplicationsTwoTone";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeftIcon";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PasswordIcon from "@mui/icons-material/Password";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import { Text, Link } from "components/common";
import { AUTH_ROUTER_PATHS, AUTH_ROUTER_ROLE_PATHS } from "types/navigation";
import { useGetMe, useSignOut } from "hooks";
import VrpanoTwoToneIcon from "@mui/icons-material/VrpanoTwoTone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MessageIcon from "@mui/icons-material/Message";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useLocation } from "react-router";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  borderRadius: "50px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface INavigationButton {
  activeIcon: React.ReactNode;
  icon: React.ReactNode;
  isOpen: boolean;
  label: string;
  onClick?: () => void;
  to?: string;
}

const NavigationButton: React.FC<INavigationButton> = ({
  icon,
  isOpen,
  label,
  onClick,
  to,
  activeIcon,
}) => {
  const location = useLocation(); // Get the current location
  const isActive = to === location.pathname;
  const Wrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    if (to) {
      return <Link path={to}>{children}</Link>;
    } else {
      return <div onClick={onClick}>{children}</div>;
    }
  };

  return (
    <Wrapper>
      <ListItemButton
        sx={{
          // minHeight: 48,
          justifyContent: isOpen ? "initial" : "center",
          px: 2.5,
          position: "relative",
          // padding: "3px",
          // marginTop: "5px",

          // borderTopLeftRadius: isActive ? "150px" : "",
        }}
      >
        <Box
          sx={{
            borderLeft: isActive ? "2px solid #FFE500" : "",
            position: isActive ? "absolute" : "absolute",
            height: isActive ? "25px" : "25px",
            top: isActive ? "0" : "0",
            right: isActive ? "0" : "0",
            left: isActive ? "0" : "0",
            bottom: isActive ? "0" : "0",
            marginTop: isActive ? "14px" : "0",
            // marginLeft: isActive ? "-20px" : "20px",
          }}
        ></Box>
        <ListItemIcon
          sx={{
            display: "flex",

            // pl: "-10px",
            minWidth: 0,
            mr: isOpen ? 2 : "auto",
            height: "30px",
            justifyContent: "center",
          }}
        >
          <Box>{isActive ? activeIcon : icon}</Box>
        </ListItemIcon>
        <ListItemText
          primary={
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "12px",
                fontFamily: "PlusJakartaSans-Medium",
                textTransform: "capitalize",
                color: isActive ? "#FFE500" : "",
              }}
            >
              {label}
            </div>
          }
          sx={{ opacity: isOpen ? 1 : 0, textDecoration: "none" }}
        />
      </ListItemButton>
      <Divider />
    </Wrapper>
  );
};

const ROUTE_ICONS = [
  {
    activeIcon: <img src={ManageUsersActive} alt="Manage users icon" />,
    icon: <img src={ManageUsersInactive} alt="Manage users icon" />,
    label: "Manage users",
    path: AUTH_ROUTER_PATHS.MANAGE_USERS,
  },
  {
    activeIcon: <img src={ManageListingsIconsActive} alt="Manage users icon" />,
    icon: <img src={ManageListingsIcons} alt="Manage users icon" />,
    label: "Manage listings",
    path: AUTH_ROUTER_PATHS.MANAGE_LISTINGS,
  },
  {
    activeIcon: <img src={BulkAddListingsIconActive} alt="Manage users icon" />,
    icon: <img src={BulkAddListingsIcon} alt="Manage users icon" />,
    label: "Bulk add listings",
    path: AUTH_ROUTER_PATHS.BULK_ADD_LISTINGS,
  },
  {
    activeIcon: (
      <img src={ManageBulkGroupsIconActive} alt="Manage users icon" />
    ),
    icon: <img src={ManageBulkGroupsIcon} alt="Manage users icon" />,
    label: "Manage bulk groups",
    path: AUTH_ROUTER_PATHS.MANAGE_BULK_GROUPS,
  },
  {
    activeIcon: <img src={VendorZipCodesIconActive} alt="Manage users icon" />,
    icon: <img src={VendorZipCodesIcon} alt="Manage users icon" />,
    label: "Vendor zipcodes",
    path: AUTH_ROUTER_PATHS.VENDOR_ZIPCODES,
  },
  {
    activeIcon: <img src={VendorEventsIconsActive} alt="Manage users icon" />,
    icon: <img src={VendorEventsIcons} alt="Manage users icon" />,
    label: "Vendor events",
    path: AUTH_ROUTER_PATHS.VENDOR_EVENTS,
  },
  // {
  //   activeIcon: <img src={ManageFilesIconsActive} alt="Manage users icon" />,
  //   icon: <img src={ManageFilesIcons} alt="Manage users icon" />,
  //   label: "Manage files",
  //   path: AUTH_ROUTER_PATHS.MANAGE_FILES,
  // },
  // {
  //   activeIcon: (
  //     <img src={ManageWalkthroughIconsActive} alt="Manage users icon" />
  //   ),
  //   icon: <img src={ManageWalkthroughIcons} alt="Manage users icon" />,
  //   label: "Manage Walkthrough",
  //   path: AUTH_ROUTER_PATHS.MANAGE_VIRTUAL_WALKTHROUGHS,
  // },
  // {
  //   activeIcon: <img src={ManageTitlesIconsActive} alt="Manage users icon" />,
  //   icon: <img src={ManageTitlesIcons} alt="Manage users icon" />,
  //   label: "Manage titles",
  //   path: AUTH_ROUTER_PATHS.MANAGE_TITLES,
  // },
  // {
  //   activeIcon: <img src={GenerateDocumentsActive} alt="Manage users icon" />,
  //   icon: <img src={GenerateDocumentsactive} alt="Manage users icon" />,
  //   label: "Generate documents",
  //   path: AUTH_ROUTER_PATHS.GENERATE_DOCUMENTS,
  // },
  // {
  //   activeIcon: <img src={SettingsIconActive} alt="Manage users icon" />,
  //   icon: <img src={SettingsIcon} alt="Manage users icon" />,
  //   label: "Settings",
  //   path: AUTH_ROUTER_PATHS.APP_SETTINGS,
  // },
  // {
  //   activeIcon: <img src={ChangePasswordActive} alt="Manage users icon" />,
  //   icon: <img src={ChangePasswordactive} alt="Manage users icon" />,
  //   label: "Change password",
  //   path: AUTH_ROUTER_PATHS.CHANGE_PASSWORD,
  // },
  // {
  //   activeIcon: <img src={MessagesIconActive} alt="Manage users icon" />,
  //   icon: <img src={MessagesIcon} alt="Manage users icon" />,
  //   label: "Messages",
  //   path: AUTH_ROUTER_PATHS.MESSAGES,
  // },
];

const PAGE_END_ROUTE_ICONS = [
  {
    activeIcon: <img src={MoneyReciveIconActive} alt="Manage users icon" />,
    icon: <img src={MoneyReciveIcon} alt="Manage users icon" />,
    label: "Set Price",
    path: AUTH_ROUTER_PATHS.APP_SETTINGS,
  },
  {
    activeIcon: <img src={MessagesIconActive} alt="Manage users icon" />,
    icon: <img src={MessagesIcon} alt="Manage users icon" />,
    label: "Messages",
    path: AUTH_ROUTER_PATHS.MESSAGES,
  },
];

interface OwnProps {
  children: React.ReactNode;
}

type TAuthenticatedView = OwnProps;

export const AuthenticatedView: React.FC<TAuthenticatedView> = ({
  children,
}) => {
  const { signOut } = useSignOut();
  const { user } = useGetMe();
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const generateNavButtonList = () => {
    if (!user) return null;

    const { isAdmin, isListingSpecialist, isVendor } = user;
    let availablePaths: string[] = [];

    if (isAdmin) availablePaths = [...AUTH_ROUTER_ROLE_PATHS.ADMINISTRATOR];
    if (isListingSpecialist)
      availablePaths = [
        ...availablePaths,
        ...AUTH_ROUTER_ROLE_PATHS.LISTING_SPECIALIST,
      ];
    if (isVendor)
      availablePaths = [...availablePaths, ...AUTH_ROUTER_ROLE_PATHS.VENDOR];

    // Get rid of duplicates
    availablePaths = [...new Set(availablePaths)];

    const roleFilteredRoutes = ROUTE_ICONS.filter((route) =>
      availablePaths.includes(route.path)
    );

    return roleFilteredRoutes.map(({ path, icon, label, activeIcon }) => (
      <NavigationButton
        icon={icon}
        activeIcon={activeIcon}
        isOpen={open}
        label={label}
        to={path}
        key={path}
      />
    ));
  };
  const generateNavButtonEndList = () => {
    if (!user) return null;

    const { isAdmin, isListingSpecialist, isVendor } = user;
    let availablePaths: string[] = [];

    if (isAdmin) availablePaths = [...AUTH_ROUTER_ROLE_PATHS.ADMINISTRATOR];
    if (isListingSpecialist)
      availablePaths = [
        ...availablePaths,
        ...AUTH_ROUTER_ROLE_PATHS.LISTING_SPECIALIST,
      ];
    if (isVendor)
      availablePaths = [...availablePaths, ...AUTH_ROUTER_ROLE_PATHS.VENDOR];

    // Get rid of duplicates
    availablePaths = [...new Set(availablePaths)];

    const roleFilteredRoutes = PAGE_END_ROUTE_ICONS.filter((route) =>
      availablePaths.includes(route.path)
    );

    return roleFilteredRoutes.map(({ path, icon, label, activeIcon }) => (
      <NavigationButton
        icon={icon}
        activeIcon={activeIcon}
        isOpen={open}
        label={label}
        to={path}
        key={path}
      />
    ));
  };
  const isDesktop = useMediaQuery("(min-width: 900px)");
  return (
    <Box
      sx={{
        display: "flex",
        background: "black",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <CssBaseline />
      {/* <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Text variant="h6" color="#fff">
            Admin panel
          </Text>
        </Toolbar>
      </AppBar> */}
      <Box
        sx={{
          display: "flex",
          backgroundColor: "black",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="toggle drawer"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 0.7,
            display: { xs: "none", md: "flex" },
          }}
        >
          {open ? (
            <img
              src={ChevronLeftIcon}
              style={{
                position: "absolute",
                marginLeft: "507px",
                top: "72px",
                height: "30px",
                zIndex: 995,
                transition: "0.2s ease-in-out",
              }}
            />
          ) : (
            <img
              src={ChevronRightIcon}
              style={{
                position: "absolute",
                marginLeft: "157px",
                top: "72px",
                height: "30px",
                zIndex: 995,
                transition: "0.2s ease-in-out",
              }}
            />
          )}
        </IconButton>
        <Drawer
          variant="permanent"
          open={isDesktop ? open : false}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "black",
              color: "white",
            },
          }}
        >
          {open ? (
            <div>
              <img
                src={logo}
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  height: "60px",
                  paddingLeft: "8px",
                  marginTop: "10px",
                }}
              />
            </div>
          ) : (
            <div>
              <img
                src={logoOpen}
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  height: "60px",
                  paddingLeft: "8px",
                  marginTop: "10px",
                }}
              />
            </div>
          )}
          <DrawerHeader
            style={{
              marginBottom: "-50px",
              // paddingTop: "10px",
            }}
          >
            {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )} */}
            {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton> */}
            {/* </IconButton> */}
          </DrawerHeader>
          <div
            style={{
              backgroundColor: "#565656",
              width: open ? "90%" : "60%",
              alignSelf: "center",
            }}
          >
            <Divider variant="middle" />
          </div>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // backgroundColor: "red",
              height: "100%",
            }}
          >
            {" "}
            <List>
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                  padding: "0px",
                  margin: "0px",
                  // borderLeft: "8px solid yellow",
                }}
              >
                {generateNavButtonList()}
              </ListItem>
            </List>
            <List>
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                  padding: "0px",
                  margin: "0px",
                  // borderLeft: "8px solid yellow",
                }}
              >
                {generateNavButtonEndList()}
              </ListItem>
              <NavigationButton
                icon={<img src={LogoutActiveIcon} alt="Manage users icon" />}
                activeIcon={null}
                isOpen={open}
                label="Logout"
                onClick={() => {
                  signOut();
                }}
              />
            </List>
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingInline: { xs: "5%", md: "3%" },
          paddingTop: "30px",
          background: "white",
          borderRadius: "20px",
          marginTop: "16px",
          marginBottom: "15px",
          marginRight: "15px",
          minHeight: "96vh",
          width: "70%",
        }}
      >
        {/* <DrawerHeader /> */}
        {children}
      </Box>
    </Box>
  );
};
