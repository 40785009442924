import { Spa } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { notification } from "antd";
import {
  Button,
  CardModal,
  ErrorMessage,
  LoadingIndicator,
  PDFViewer,
  Table,
  Text,
} from "components/common";
import { DIMENSIONS } from "consts";
import {
  useDownloadDocuments,
  useGetDocuments,
  useRemoveDocs,
  useSubmitInspectionDocs,
  useUploadFiles,
} from "hooks";
import { useEffect, useRef, useState } from "react";
import { useDocumentStore } from "store";
import { TableData, TableDataItem } from "types/components";
import {
  Listing,
  ListingDocument,
  ListingDocumentStatus,
  ListingDocumentType,
} from "types/entities";
import {
  SubmitInspectionDocsParams,
  UploadDocsFunctionParams,
} from "types/functions";
import {
  INITIAL_LISTING_EVERSIGN_DOCS_TABLE_DATA,
  INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA,
} from "views/ManageListings";

// @TODO: add new section that lists only eversign documents
const INITIAL_DOCUMENTS_FORM: UploadDocsFunctionParams = {
  listingId: "",
  isTitle: false,
  files: null,
};

const INITIAL_MODAL_STATE = {
  isOpen: false,
};

interface OwnProps {
  selectedListing: string;
  reloadListing: () => void;
}

export const ManageListingsInspectionDocs: React.FC<OwnProps> = ({
  selectedListing,
  reloadListing,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [api, contextHolder] = notification.useNotification();
  const { submitInspectionDocs } = useSubmitInspectionDocs();
  const { downloadDocuments } = useDownloadDocuments();
  const { getDocuments } = useGetDocuments();
  const { uploadDocs, loadingDocs } = useUploadFiles();
  const {
    removeDocs,
    loading: loadingRemoveDocs,
    error: errorRemoveDocs,
  } = useRemoveDocs();
  const { documentsForDownload, documents } = useDocumentStore();
  const [docsForm, setDocsForm] = useState({ ...INITIAL_DOCUMENTS_FORM });
  const [selectedInspectionDocs, setSelectedInspectionDocs] = useState<
    string[]
  >([]);
  const [listingInspectionDocsTableData, setListingInspectionDocsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA });
  const [
    listingApprovedInspectionDocsTableData,
    setListingApprovedInspectionDocsTableData,
  ] = useState<TableData>({ ...INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA });
  const [listingEversignDocsTableData, setListingEversignDocsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_EVERSIGN_DOCS_TABLE_DATA });
  const [selectedInspectionDocPreview, setSelectedInspectionDocPreview] =
    useState<string>("");
  const [
    selectedInspectionDocPreviewBase64,
    setSelectedInspectionDocPreviewBase64,
  ] = useState<string>("");
  const [modalState, setModalState] = useState({ ...INITIAL_MODAL_STATE });
  const [documetGet, setDocumentGet] = useState(false);

  const handleUploadDocs = (newDocsForm: UploadDocsFunctionParams) => {
    // console.log(newDocsForm, "======");
    // console.log('----')
    setDocsForm({ ...newDocsForm });
    uploadDocs(newDocsForm).then((success: boolean) => {
      if (success && docsForm.listingId) {
        api.success({
          message: `Successfully uploaded documents`,
          placement: "bottomRight",
        });
        // Reset the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setDocsForm({ ...INITIAL_DOCUMENTS_FORM });
        setDocumentGet(!documetGet);
        // fetchDocuments(docsForm.listingId);
      }
    });
  };

  const handleRowClickMultiple = (
    item: TableDataItem,
    currState: string[],
    setStateFunc: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    let newState = [...currState.filter((elem) => elem !== item.id)];

    if (newState.length === currState.length) {
      // Length did not change, meaning an item was selected
      newState = [...newState, item.id as string];
    }
    setStateFunc([...newState]);
  };

  const mapAllInspectionDocsToTableData = (
    data: ListingDocument[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.file_name;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapApprovedInspectionDocsToTableData = (
    data: ListingDocument[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      if (item.status !== ListingDocumentStatus.APPROVED) return;

      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.file_name;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapEversignDocsToTableData = (
    data: ListingDocument[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      if (
        item.status !== ListingDocumentStatus.APPROVED ||
        item.eversign_document === null
      )
        return;

      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.file_name;

      newTableData.headers.forEach((header) => {
        const key = header.key;
        switch (key) {
          case "type":
            // @ts-ignore
            tableItem[key] = item[key];
            break;
          case "link":
            // @ts-ignore
            tableItem[key] = `https://realtybase.eversign.com/documents/${
              item.eversign_document!.eversign_hash
            }`;
            break;

          default:
            break;
        }
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const getInspectionDocBase64Data = (fileName: string) => {
    const document = documentsForDownload.find(
      (document) => document.file_name === fileName
    );
    return document?.data || "";
  };

  const downloadDocument = () => {
    if (!selectedInspectionDocPreview) return;
    const linkSource = selectedInspectionDocPreviewBase64;
    const downloadLink = document.createElement("a");
    const fileName = selectedInspectionDocPreview;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleSubmitInspectionDocs = ({
    listingId,
    documents,
  }: SubmitInspectionDocsParams) => {
    submitInspectionDocs({
      listingId,
      documents,
    }).then(() => {
      downloadDocuments({ listingId, type: ListingDocumentType.INSPECTION });
      setSelectedInspectionDocs([]);
      reloadListing();
    });
  };

  const handleRemoveInspectionDocs = ({
    documents,
  }: {
    documents: string[];
  }) => {
    // documents are of the following format:
    // {documentId}.{listingId}.{file extension}
    const documentIds = documents.map((document) => {
      const [documentId, ...rest] = document.split(".");
      return documentId;
    });
    removeDocs({
      documentIds,
    }).then(() => {
      downloadDocuments({
        listingId: selectedListing,
        type: ListingDocumentType.INSPECTION,
      });
      setSelectedInspectionDocs([]);
      reloadListing();
    });
  };

  useEffect(() => {
    mapAllInspectionDocsToTableData(
      documentsForDownload,
      INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA,
      setListingInspectionDocsTableData
    );
    mapApprovedInspectionDocsToTableData(
      documentsForDownload,
      INITIAL_LISTING_INSPECTION_DOCS_TABLE_DATA,
      setListingApprovedInspectionDocsTableData
    );
  }, [documentsForDownload, documetGet]);

  useEffect(() => {
    // console.log("run here ,=====")
    mapEversignDocsToTableData(
      documents,
      INITIAL_LISTING_EVERSIGN_DOCS_TABLE_DATA,
      setListingEversignDocsTableData
    );
  }, [documents, documetGet]);

  console.log({ listingEversignDocsTableData });

  useEffect(() => {
    if (!selectedListing) return;
    else {
      downloadDocuments({
        listingId: selectedListing,
        type: ListingDocumentType.INSPECTION,
      });
      getDocuments({
        listingId: selectedListing,
      });
      setSelectedInspectionDocs([]);
      setDocsForm({ ...docsForm, listingId: selectedListing });
    }
  }, [selectedListing, documetGet]);

  useEffect(() => {
    setSelectedInspectionDocPreviewBase64(
      getInspectionDocBase64Data(selectedInspectionDocPreview)
    );
  }, [selectedInspectionDocPreview]);

  useEffect(() => {
    if (!errorRemoveDocs && !loadingRemoveDocs && modalState.isOpen) {
      setModalState({ ...INITIAL_MODAL_STATE });
    }
  }, [errorRemoveDocs, loadingRemoveDocs]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "70vh",
        }}
      >
        <Box>
          <Text
            sx={{
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            Select Inspection Documents
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "0",
              margin: "0",
              marginTop: "10px",
              // p: 1,
              // m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            {" "}
            <Table
              tableData={listingInspectionDocsTableData}
              onRowClick={(item) =>
                handleRowClickMultiple(
                  item,
                  selectedInspectionDocs,
                  setSelectedInspectionDocs
                )
              }
              selectedRowIds={selectedInspectionDocs}
            />
          </Box>

          <Text
            sx={{
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: "14px",
              fontWeight: "600",
            }}
            marginTop="20px"
          >
            Approved Documents
          </Text>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
              // p: 1,
              // m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Table tableData={listingApprovedInspectionDocsTableData} />
          </Box>

          <Button
            variant="contained"
            style={{
              borderRadius: "30px",
              whiteSpace: "nowrap",
              fontSize: "12px",
              backgroundColor: "#FFE500",
              color: "black",
              padding: "8px 20px 8px 20px",
              textTransform: "none",
              width: "fit-content",
              marginTop: "20px",
              fontFamily: "PlusJakartaSans-Bold",
            }}
            // color=""
            fullWidth
            onClick={() => fileInputRef.current?.click()}
            // onClick={() =>
            //   handleSubmitInspectionDocs({
            //     listingId: selectedListing,
            //     documents: [],
            //   })
            // }
          >
            {loadingDocs ? (
              <LoadingIndicator indicatorColor="white" size={24} />
            ) : (
              "Upload Documents"
            )}
            <input
              ref={fileInputRef}
              type="file"
              hidden
              multiple
              onChange={(e) =>
                handleUploadDocs({ ...docsForm, files: e.target.files })
              }
            />
          </Button>
          <Box sx={{ marginTop: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  // onChange={(e) =>
                  //   handleChangeListingStatus(
                  //     ListingStatus.APPROVED,
                  //     e.target.checked
                  //   )
                  // }
                  // checked={determineListingStatus(form, ListingStatus.APPROVED)}
                  sx={{
                    background: "transparent",
                    border: 0,
                    "&.Mui-checked": {
                      color: "#ABD584",
                      "& .MuiSvgIcon-root": {
                        backgroundColor: "transparent",
                        border: "none",
                      },
                    },
                  }}
                />
              }
              label="Title Document"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "PlusJakartaSans-Regular",
                },
              }}
            />
          </Box>
          <Text
            sx={{
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: "14px",
              fontWeight: "600",
            }}
            marginTop="20px"
          >
            Preview Document
          </Text>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "5px",
              marginTop: "5px",
              // p: 1,
              // m: 1,
              bgcolor: "background.paper",
              // borderRadius: 1,
            }}
          >
            <TextField
              sx={{
                width: { xs: "100%", sm: "70%" },
                "& .MuiInputBase-root": {
                  borderRadius: "30px",
                  border: "1px solid #EFEFEF",
                  height: "40px",
                  paddingRight: "32px", // Adjust padding for the select icon
                },
                "& .MuiInputLabel-root": {
                  fontSize: "12px",
                  transform: "translate(14px, 14px)", // Align label
                  lineHeight: "1.2", // Adjust line height for better alignment
                },
                "& .MuiSvgIcon-root": {
                  right: "8px", // Position the select icon
                },
              }}
              select
              label="Select Document"
              value={selectedInspectionDocPreview}
              onChange={(e) => setSelectedInspectionDocPreview(e.target.value)}
            >
              {documentsForDownload.map((item) => (
                <MenuItem key={item.file_name} value={item.file_name}>
                  {item.file_name}
                </MenuItem>
              ))}
            </TextField>

            <Button
              variant="contained"
              sx={{
                borderRadius: "30px",
                fontSize: "12px",
                color: "black !important",
                width: { xs: "120px", sm: "30%" },
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
              onClick={downloadDocument}
              disabled={!selectedInspectionDocPreview}
            >
              Download File
            </Button>
          </Box>

          {/* <Text
            sx={{
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: "14px",
              fontWeight: "600",
            }}
            marginTop="20px"
          >
            Eversign Documents
          </Text> */}
          <Box
            marginTop="20px"
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 1,
              // m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Table tableData={listingEversignDocsTableData} />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "10px",
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Button
            variant="contained"
            style={{
              borderRadius: "30px",
              whiteSpace: "nowrap",
              fontSize: "12px",
              textTransform: "none",
            }}
            color="error"
            fullWidth
            onClick={() =>
              setModalState({
                isOpen: true,
              })
            }
            disabled={selectedInspectionDocs.length === 0}
          >
            Remove Documents
          </Button>

          <Button
            variant="contained"
            style={{
              borderRadius: "30px",
              whiteSpace: "nowrap",
              fontSize: "12px",
              textTransform: "none",
            }}
            color="success"
            fullWidth
            onClick={() =>
              handleSubmitInspectionDocs({
                listingId: selectedListing,
                documents: selectedInspectionDocs,
              })
            }
            disabled={selectedInspectionDocs.length === 0}
          >
            Submit Documents
          </Button>

          <Button
            variant="contained"
            style={{
              borderRadius: "30px",
              whiteSpace: "nowrap",
              fontSize: "12px",
              backgroundColor: "#FFE500",
              color: "black",
              padding: "10px 0px 10px 0px",
              textTransform: "none",
            }}
            // color=""
            fullWidth
            onClick={() =>
              handleSubmitInspectionDocs({
                listingId: selectedListing,
                documents: [],
              })
            }
          >
            Clear Documents
          </Button>
        </Box>
      </Box>

      {/* <Grid container alignItems="flex-start" marginTop="20px" spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Select Inspection Documents
              </Text>
            </Grid>
            <Grid item xs={6}>
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Preview Document
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              maxHeight={parseInt(DIMENSIONS.MAX_GRID_ITEM_HEIGHT) / 2}
              style={{ overflowY: "auto" }}
            >
              <Table
                tableData={listingInspectionDocsTableData}
                onRowClick={(item) =>
                  handleRowClickMultiple(
                    item,
                    selectedInspectionDocs,
                    setSelectedInspectionDocs
                  )
                }
                selectedRowIds={selectedInspectionDocs}
              />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Approved Documents
              </Text>
            </Grid>
            <Grid
              item
              xs={12}
              maxHeight={parseInt(DIMENSIONS.MAX_GRID_ITEM_HEIGHT) / 2}
              style={{ overflowY: "auto" }}
            >
              <Table tableData={listingApprovedInspectionDocsTableData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                select
                fullWidth
                label="Select Document"
                value={selectedInspectionDocPreview}
                onChange={(e) =>
                  setSelectedInspectionDocPreview(e.target.value)
                }
              >
                {documentsForDownload.map((item) => (
                  <MenuItem key={item.file_name} value={item.file_name}>
                    {item.file_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                fullWidth
                onClick={downloadDocument}
                disabled={!selectedInspectionDocPreview}
              >
                Download File
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}
              style={{ overflowY: "auto" }}
            >
              <PDFViewer base64={selectedInspectionDocPreviewBase64} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() =>
                  setModalState({
                    isOpen: true,
                  })
                }
                disabled={selectedInspectionDocs.length === 0}
              >
                Remove Inspection Documents
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={() =>
                  handleSubmitInspectionDocs({
                    listingId: selectedListing,
                    documents: selectedInspectionDocs,
                  })
                }
                disabled={selectedInspectionDocs.length === 0}
              >
                Submit Inspection Documents
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() =>
                  handleSubmitInspectionDocs({
                    listingId: selectedListing,
                    documents: [],
                  })
                }
              >
                Clear Inspection Documents
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Eversign Documents
              </Text>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            maxHeight={parseInt(DIMENSIONS.MAX_GRID_ITEM_HEIGHT) / 2}
            style={{ overflowY: "auto" }}
          >
            <Table tableData={listingEversignDocsTableData} />
          </Grid>
        </Grid>
      </Grid> */}

      <CardModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ ...INITIAL_MODAL_STATE })}
      >
        <Stack spacing={2} direction="column">
          <Text variant="h6">
            Are you sure you want to remove these documents from the database?
          </Text>
          {selectedInspectionDocs.map((selectedInspectionDoc) => (
            <Text variant="subtitle1" key={selectedInspectionDoc}>
              {selectedInspectionDoc}
            </Text>
          ))}
          {errorRemoveDocs && <ErrorMessage />}
          <Button
            variant="contained"
            type="submit"
            disabled={loadingRemoveDocs}
            onClick={() =>
              handleRemoveInspectionDocs({ documents: selectedInspectionDocs })
            }
          >
            {loadingRemoveDocs ? (
              <LoadingIndicator size={24} indicatorColor="white" />
            ) : (
              "Confirm"
            )}
          </Button>
        </Stack>
      </CardModal>
    </>
  );
};
