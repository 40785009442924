import create from "zustand";

import { UserService } from "services";
import {
  EntityIdTimeslotsHashMap,
  UserInStore,
  UsersInStore,
} from "types/store";
import { User } from "types/entities";
import {
  AssignVendorToEventFunction,
  CreateUserFunction,
  GenerateResetCodeFunction,
  GetVendorsFunction,
  GetVendorsWithTimeslotsFunction,
  GetUsersFunction,
  GetVendorsWithZipcodesFunction,
  ResetPasswordFunction,
  SetVendorZipcodesFunction,
  SignInFunction,
  SignOutFunction,
  VerifyResetCodeFunction,
  SetUserPasswordParams,
  SetUserPasswordFunction,
  GetVendorAvailableDatesInMonthFunction,
  GetVendorAvailableTimeslotsFunction,
  RemoveUserFunction,
  GetUserFunction,
  UpdateUserFunction,
  UpdateProfilePictureFunctionParams,
  UpdateProfilePictureFunction,
} from "types/functions";
import { ChangeFunctionReturnType } from "types/utils";
import { DateFormatter } from "utils";
import { useEventsStore } from "store/events";

interface UserStore {
  /**
   * Client that is using this app. Currently authorized Client.
   */
  authorizedUser: UserInStore;

  /**
   * Other users that the Client can see. It may also contain Client itself but
   * in a different context.
   *
   * @TODO: We need to come up with some kind of merging logic in case functions other
   * than `getVendorsWithZipcodes` gets to insert users into store.
   */

  users: UsersInStore;
  vendorIdDateTimeslotsHashMap: EntityIdTimeslotsHashMap;
  user: User | {};
  getMe: () => Promise<User>;
  getUser: GetUserFunction;
  updateUser: UpdateUserFunction;
  updateUserProfilePicture: UpdateProfilePictureFunction;
  signIn: SignInFunction;
  signOut: SignOutFunction;
  generateResetCode: GenerateResetCodeFunction;
  verifyResetCode: VerifyResetCodeFunction;
  resetPassword: ResetPasswordFunction;
  createUser: CreateUserFunction;
  getVendorsWithZipcodes: ChangeFunctionReturnType<
    GetVendorsWithZipcodesFunction,
    Promise<void>
  >;
  getVendors: ChangeFunctionReturnType<GetVendorsFunction, Promise<void>>;
  setVendorZipcodes: ChangeFunctionReturnType<
    SetVendorZipcodesFunction,
    Promise<void>
  >;
  getVendorsWithTimeslots: ChangeFunctionReturnType<
    GetVendorsWithTimeslotsFunction,
    Promise<void>
  >;
  assignVendorToEvent: ChangeFunctionReturnType<
    AssignVendorToEventFunction,
    Promise<void>
  >;
  getUsers: GetUsersFunction;
  setUserPassword: SetUserPasswordFunction;
  getVendorAvailableDatesInMonth: GetVendorAvailableDatesInMonthFunction;
  getVendorAvailableTimeslots: GetVendorAvailableTimeslotsFunction;
  removeUser: RemoveUserFunction;
}

export const useUserStore = create<UserStore>((set, get) => ({
  user: {},
  authorizedUser: undefined,
  users: [],
  vendorIdDateTimeslotsHashMap: {},
  getMe: async () => {
    try {
      const user = await UserService.getMe();
      set({ authorizedUser: user });
      return user;
    } catch (error) {
      throw error;
    }
  },
  signIn: async (...args: Parameters<SignInFunction>) => {
    try {
      const user = await UserService.signIn(...args);
      set({ authorizedUser: user });
      return user;
    } catch (error) {
      throw error;
    }
  },
  signOut: async () => {
    try {
      await UserService.signOut();
      set({ authorizedUser: undefined });
    } catch (error) {
      throw error;
    }
  },
  generateResetCode: async (...args: Parameters<GenerateResetCodeFunction>) => {
    try {
      await UserService.generateResetCode(...args);
    } catch (error) {
      throw error;
    }
  },
  verifyResetCode: async (...args: Parameters<VerifyResetCodeFunction>) => {
    try {
      return await UserService.verifyResetCode(...args);
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (...args: Parameters<ResetPasswordFunction>) => {
    try {
      await UserService.resetPassword(...args);
    } catch (error) {
      throw error;
    }
  },
  createUser: async (...args: Parameters<CreateUserFunction>) => {
    try {
      return await UserService.createUser(...args);
    } catch (error) {
      throw error;
    }
  },
  getVendorsWithZipcodes: async (
    ...args: Parameters<GetVendorsWithZipcodesFunction>
  ) => {
    try {
      const results = await UserService.Vendors.getVendorsWithZipcodes(...args);
      set({ users: results });
    } catch (error) {
      throw error;
    }
  },
  setVendorZipcodes: async (...args: Parameters<SetVendorZipcodesFunction>) => {
    try {
      const results = await UserService.Vendors.setZipcodes(...args);
      // Find edited user then update.
      const currentUsers = get().users;
      if (!currentUsers) {
        set({ users: results });
        return;
      }

      set({
        users: currentUsers.map((currentUser) => {
          if (currentUser.id === args[0]) return results[0];
          return currentUser;
        }),
      });
    } catch (error) {
      throw error;
    }
  },
  getVendors: async (...args: Parameters<GetVendorsFunction>) => {
    try {
      const results = await UserService.Vendors.getVendors(...args);
      // @TODO: overriding users may cause bugs in the future. It would be much better to merge them. We'll leave that for now since the only user type that is held inside state.users is Vendors.
      set({ users: results });
    } catch (error) {
      throw error;
    }
  },
  getVendorsWithTimeslots: async (
    ...args: Parameters<GetVendorsWithTimeslotsFunction>
  ) => {
    try {
      const [params] = args;
      const { date } = params;
      const results = await UserService.Vendors.getVendorsWithTimeslots(
        ...args
      );
      // @TODO: overriding users may cause bugs in the future. It would be much better to merge them. We'll leave that for now since the only user type that is held inside state.users is Vendors.
      const timeslots: EntityIdTimeslotsHashMap = {};
      results.forEach((r) => {
        timeslots[r.vendor.id] = {};
        timeslots[r.vendor.id][DateFormatter.toDateString(date)] = r.timeslots;
      });
      set({
        users: results.map((r) => r.vendor),
        vendorIdDateTimeslotsHashMap: {
          ...get().vendorIdDateTimeslotsHashMap,
          ...timeslots,
        },
      });
    } catch (error) {
      throw error;
    }
  },
  assignVendorToEvent: async (
    ...args: Parameters<AssignVendorToEventFunction>
  ) => {
    try {
      const changedEvent = await UserService.Vendors.assignToEvent(...args);
      const eventsInEventsStore = useEventsStore.getState().events;
      const indexOfEdited = eventsInEventsStore.findIndex(
        (e) => e.id === changedEvent.id
      );

      // Add or edit changed event.
      if (indexOfEdited === -1) {
        eventsInEventsStore.push(changedEvent);
      } else {
        eventsInEventsStore[indexOfEdited] = changedEvent;
      }

      useEventsStore.setState({
        events: eventsInEventsStore,
      });
    } catch (error) {
      throw error;
    }
  },
  getVendorAvailableDatesInMonth: async (
    ...args: Parameters<GetVendorAvailableDatesInMonthFunction>
  ) => {
    try {
      const dates = await UserService.Vendors.getVendorAvailableDatesInMonth(
        ...args
      );

      return dates;
    } catch (error) {
      throw error;
    }
  },
  getVendorAvailableTimeslots: async (
    ...args: Parameters<GetVendorAvailableTimeslotsFunction>
  ) => {
    try {
      const dates = await UserService.Vendors.getVendorAvailableTimeslots(
        ...args
      );

      return dates;
    } catch (error) {
      throw error;
    }
  },
  getUsers: async (...args: Parameters<GetUsersFunction>) => {
    try {
      const users = await UserService.getUsers(...args);
      set({ users });
      return users;
    } catch (error) {
      throw error;
    }
  },
  setUserPassword: async (...args: Parameters<SetUserPasswordFunction>) => {
    try {
      const result = await UserService.setUserPassword(...args);
      return result;
    } catch (error) {
      throw error;
    }
  },
  removeUser: async (...args: Parameters<RemoveUserFunction>) => {
    try {
      const response = await UserService.removeUser(...args);

      return response;
    } catch (error) {
      throw error;
    }
  },

  getUser: async (...args: Parameters<GetUserFunction>) => {
    try {
      const user = await UserService.getUser(...args);
      // set({ user });

      return user;
    } catch (error) {
      throw error;
    }
  },

  updateUserProfilePicture: async (
    ...args: Parameters<UpdateProfilePictureFunction>
  ) => {
    try {
      const user = await UserService.updateUserProfilePicture(...args);
      set({ user });

      return user;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (...args: Parameters<UpdateUserFunction>) => {
    try {
      debugger
      const user = await UserService.updateUser(...args);
      set({ user });

      return user;
    } catch (error) {
      throw error;
    }
  },
}));
