import { HttpService } from "services";
import { ListingColumn } from "types/entities";
import { AuthorizationLevel } from "types/http";

/**
 * Retrieve detailed information about listing
 *
 * @throws {HttpServiceException}
 */
export const getListingColumn = async () => {
  try {
    let url = `/column`;

    const res: ListingColumn = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
