import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
import {
  CardModal,
  ClickableImage,
  ErrorMessage,
  LoadingIndicator,
  Text,
} from "components/common";
import {
  useUploadFiles,
  useSetPhotos,
  useGetPhotos,
  useRemovePhoto,
} from "hooks";
import { useEffect, useRef, useState } from "react";
import { usePhotoStore } from "store";
import { DetailedListing, Photo } from "types/entities";
import image1 from "../../../assets/images/Frame 538.svg";
import seacrhIconHover from "../../../assets/images/seacrhiconhover.svg";
import Addicon from "../../../assets/images/Addicon.svg";
import DeleteIcon from "../../../assets/images/delete-icon.png";
import { isDictEmpty } from "utils";
// import DeleteIcon from "@mui/icons-material/Delete";
// @types/react-reorder doesn't exist - we need to silence typescript with @ts-ignore
// @ts-ignore
import ReactReorder from "react-reorder";
import { DIMENSIONS } from "consts";
import { MAX_GRID_ITEM_HEIGHT } from "../../../consts/dimensions";
import { UploadPhotosFunctionParams } from "types/functions";

import { notification } from "antd";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface OwnProps {
  listing: DetailedListing | {};
  reloadListing: () => void;
}

const INITIAL_MODAL_STATE = {
  src: "",
  isOpen: false,
  fileName: "",
};

const INITIAL_PHOTOS_FORM: UploadPhotosFunctionParams = {
  listingId: "",
  files: null,
};

export const ManageListingsPhotoSelector: React.FC<OwnProps> = ({
  listing = {},
  reloadListing,
}) => {
  // Axios source used for cancelling photo downloads
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const CLICKABLE_IMAGE_DIMENSIONS = {
    WIDTH: 300,
    HEIGHT: 300,
  };
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { loadingPhotos, uploadPhotos } = useUploadFiles();
  const [api, contextHolder] = notification.useNotification();
  const { getPhotos, loading: loadingGetPhotos } = useGetPhotos();
  const { setPhotos, loading: loadingSetPhotos } = useSetPhotos();
  const [photoRefresh, setPhotoRefresh] = useState(false);
  const {
    removePhoto,
    loading: loadingRemovePhoto,
    error: errorRemovePhoto,
  } = useRemovePhoto();

  const { photos } = usePhotoStore();
  const [orderedPhotos, setOrderedPhotos] = useState<Photo[]>([]);

  const [modalState, setModalState] = useState({ ...INITIAL_MODAL_STATE });
  const [photosForm, setPhotosForm] = useState({ ...INITIAL_PHOTOS_FORM });

  const fetchData = (listing: DetailedListing) => {
    getPhotos({
      listingId: listing.id,
      signal: abortControllerRef.current.signal,
    });
  };

  const handleUploadPhotos = (newPhotosForm: UploadPhotosFunctionParams) => {
    // console.log(newPhotosForm, "newPhotosForm");
    setPhotosForm({ ...newPhotosForm });
    uploadPhotos(newPhotosForm).then((success: boolean) => {
      if (success && photosForm.listingId) {
        api.success({
          message: `Successfully uploaded photos`,
          placement: "bottomRight",
        });
        setPhotoRefresh(!photoRefresh);
        //fetchPhotos(photosForm.listingId);
      }
    });
  };

  const handleClick = () => {
    // Programmatically click the hidden input element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (!isDictEmpty(listing)) {
      const listingId = (listing as DetailedListing).id;
      setPhotosForm((prevState) => ({
        ...prevState,
        listingId: listingId,
      }));
    }
  }, [listing, photoRefresh]);

  useEffect(() => {
    if (!isDictEmpty(listing)) {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      fetchData(listing as DetailedListing);
    }
  }, [listing, photoRefresh]);

  useEffect(() => {
    // Map photos according to photo_urls currently set on listing
    if (!isDictEmpty(listing)) {
      const photoUrls = (listing as DetailedListing)?.photo_urls;
      const newOrderedPhotos: Photo[] = [];
      photoUrls.forEach((url) => {
        const foundPhoto: Photo | undefined = photos.find(
          (photo) => photo.url === url
        );
        if (foundPhoto) newOrderedPhotos.push(foundPhoto);
      });
      setOrderedPhotos([...newOrderedPhotos]);
    }
  }, [photos, photoRefresh]);

  useEffect(() => {
    if (!errorRemovePhoto && !loadingRemovePhoto && modalState.isOpen) {
      setModalState({ ...INITIAL_MODAL_STATE });
      fetchData(listing as DetailedListing);
    }
  }, [errorRemovePhoto, loadingRemovePhoto]);

  const [filteredPhotos, setFilteredPhotos] = useState<Photo[]>(photos);
  const handleAddToOrderedPhotos = (photo: Photo) => {
    // Do not allow duplicates
    if (
      orderedPhotos.some(
        (orderedPhoto) => orderedPhoto.file_name === photo.file_name
      )
    )
      return;
    setOrderedPhotos([...orderedPhotos, photo]);
  };

  const handleRemoveFromOrderedPhotos = (photo: Photo) => {
    const newOrderedPhotos = orderedPhotos.filter(
      (orderedPhoto) =>
        orderedPhoto.file_name !== photo.file_name &&
        orderedPhoto.url !== photo.url
    );
    setOrderedPhotos([...newOrderedPhotos]);
    setFilteredPhotos([...newOrderedPhotos]);
  };
  // Initialize filteredPhotos only once when photos change
  useEffect(() => {
    // console.log(photos, "Initial photos");
    setFilteredPhotos([...photos]); // Initialize filteredPhotos with a copy of photos
  }, [photos]); // Runs only when photos change

  const renderPhotoSelectionList = () => {
    // console.log(filteredPhotos,"filteredPhotos")
    // let hello=photos
    // setFilteredPhotos(hello);
    const movePhoto = (fromIndex: number, toIndex: number) => {
      const updatedPhotos = [...filteredPhotos];
      const [draggedPhoto] = updatedPhotos.splice(fromIndex, 1);
      updatedPhotos.splice(toIndex, 0, draggedPhoto);
      setOrderedPhotos(updatedPhotos);
      setFilteredPhotos(updatedPhotos);
    };
    const DraggablePhoto = ({
      photo,
      index,
    }: {
      photo: Photo;
      index: number;
    }) => {
      const ref = useRef<HTMLDivElement | null>(null);

      const [, drop] = useDrop({
        accept: "PHOTO",
        hover: (draggedItem: { index: number }) => {
          if (draggedItem.index !== index) {
            movePhoto(draggedItem.index, index);
            draggedItem.index = index;
          }
        },
      });

      const [{ isDragging }, drag] = useDrag({
        type: "PHOTO",
        item: { index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });

      drag(drop(ref));

      return (
        <>
          <div
            ref={ref}
            className="image-container"
            style={{
              opacity: isDragging ? 0.5 : 1,
              cursor: "move",
            }}
          >
            <div className="image-container-hover">
              <img src={seacrhIconHover} alt="Search Icon" />
            </div>
            <div className="delete-icon-container">
              <div style={{ display: "flex", gap: "5px" }}>
                <Box
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "white",
                    color: "black",
                    height: "30px",
                    width: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {index + 1}
                </Box>
                {index === 0 && (
                  <Box
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "white",
                      color: "black",
                      height: "30px",
                      width: "55px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Cover
                  </Box>
                )}
              </div>
              <IconButton
                onClick={() =>
                  setModalState({
                    isOpen: true,
                    src: photo.data,
                    fileName: photo.file_name,
                  })
                }
              >
                <img src={DeleteIcon} alt="Delete Icon" />
              </IconButton>
            </div>
            <ClickableImage
              imageProps={{
                src: photo.data,
                style: { width: "100%", height: "135px", borderRadius: "15px" },
              }}
              onClick={() => handleRemoveFromOrderedPhotos(photo)}
            />
          </div>
        </>
      );
    };

    return (
      <>
        {filteredPhotos.map((photo, index) => (
          <DraggablePhoto key={photo.file_name} photo={photo} index={index} />
        ))}

        <div
          style={{
            border: " 1px dashed #BFBFBF",
            borderRadius: "15px",
            cursor: "pointer",
          }}
          className="image-container1"
          onClick={handleClick}
        >
          {loadingPhotos ? (
            <LoadingIndicator indicatorColor="white" size={24} />
          ) : (
            <>
              <img
                src={Addicon}
                alt="Add Icon"
                style={{ marginBottom: "8px" }}
              />
              <span>Upload Image</span>
            </>
          )}
          <input
            ref={fileInputRef}
            type="file"
            hidden
            multiple
            onChange={(e) =>
              handleUploadPhotos({ ...photosForm, files: e.target.files })
            }
          />
        </div>
      </>
    );
  };

  const renderOrderedPhotosList = () => (
    <ReactReorder
      reorderId="reorder-elem-container"
      draggedClassName="reorder-elem-dragged"
      onReorder={handleReorder}
    >
      {orderedPhotos.map((photo) => (
        <div
          style={{
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
            gap: "15px", // Space between the boxes
            width: "100%",
          }}
          key={photo.file_name}
        >
          <Box sx={{ width: "calc(33.333% - 10px)" }}>
            <div className="delete-icon-container">
              <IconButton
                onClick={() =>
                  setModalState({
                    isOpen: true,
                    src: photo.data,
                    fileName: photo.file_name,
                  })
                }
              >
                {/*<DeleteIcon color="error" fontSize="large"/>*/}
                <img src={DeleteIcon} />
              </IconButton>
            </div>
            <ClickableImage
              key={photo.url}
              imageProps={{
                src: photo.data,
                style: {
                  width: "100%",
                },
              }}
              onClick={() => handleRemoveFromOrderedPhotos(photo)}
            />
          </Box>
        </div>
      ))}
    </ReactReorder>
  );

  const handleReorder = (
    event: MouseEvent,
    prevIndex: number,
    nextIndex: number
  ) => {
    // Switch places of the elements
    const newOrderedPhotos = [...orderedPhotos];
    const draggedPhoto = newOrderedPhotos[prevIndex];
    newOrderedPhotos[prevIndex] = newOrderedPhotos[nextIndex];
    newOrderedPhotos[nextIndex] = draggedPhoto;
    setOrderedPhotos([...newOrderedPhotos]);
  };

  const handleResetSelection = () => setOrderedPhotos([]);

  const handleSubmitSelection = () => {
    if (!isDictEmpty(listing)) {
      const fileNames = (
        orderedPhotos.length == filteredPhotos.length
          ? orderedPhotos
          : filteredPhotos
      ).map((photo) => photo.file_name);

      // console.log(fileNames, "fileNames");
      setPhotos({ listingId: (listing as DetailedListing).id, fileNames })
        .then
        // reloadListing
        ();
    }
  };

  const handleRemovePhoto = (fileName: string) => {
    // fileName is of the following format:
    // {photoId}.{listingId}.{file extension}
    const [photoId, ...rest] = fileName.split(".");
    removePhoto({ photoId });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "70vh",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    display: "flex",*/}
        {/*    flexWrap: "wrap", // Enables wrapping to the next line*/}
        {/*    gap: "15px", // Space between the boxes*/}
        {/*    width: "100%",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Box sx={{ width: "calc(33.333% - 10px)" }}>*/}
        {/*    <img src={image1} style={{ width: "100%" }} />*/}
        {/*  </Box>*/}
        {/*  <Box sx={{ width: "calc(33.333% - 10px)" }}>*/}
        {/*    <img src={image1} style={{ width: "100%" }} />*/}
        {/*  </Box>*/}
        {/*  <Box sx={{ width: "calc(33.333% - 10px)" }}>*/}
        {/*    <img src={image1} style={{ width: "100%" }} />*/}
        {/*  </Box>*/}
        {/*  <Box sx={{ width: "calc(33.333% - 10px)" }}>*/}
        {/*    <img src={image1} style={{ width: "100%" }} />*/}
        {/*  </Box>*/}
        {/*</Box>*/}

        {/*<Box >*/}
        {/*  <Text variant="h5" fontWeight={600} marginBottom="20px">*/}
        {/*    Select Photos*/}
        {/*  </Text>*/}
        {/*</Box>*/}
        {/*<Box >*/}
        {/*  <Text variant="h5" fontWeight={600} marginBottom="20px">*/}
        {/*    Rearrange Photos*/}
        {/*  </Text>*/}
        {/*</Box>*/}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "15px", // Space between the boxes
            width: "100%",
          }}
        >
          {loadingGetPhotos ? (
            <LoadingIndicator size={24} />
          ) : (
            <DndProvider backend={HTML5Backend}>
              {renderPhotoSelectionList()}
            </DndProvider>
          )}
        </Box>
        {/* <Grid
          xs={6}
          maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}
          style={{ overflowY: "auto" }}
        ></Grid> */}
        {/*<Grid*/}
        {/*  item*/}
        {/*  xs={6}*/}
        {/*  maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}*/}
        {/*  style={{ overflowY: "auto" }}*/}
        {/*>*/}
        {/*{loadingGetPhotos ? (*/}
        {/*    <LoadingIndicator size={24} />*/}
        {/*  ) : (*/}
        {/*    renderOrderedPhotosList()*/}
        {/*  )}*/}
        {/*</Grid>*/}
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ width: "50%" }}>
            <Button
              // variant="contained"
              style={{
                borderRadius: "30px",
                whiteSpace: "nowrap",
                fontSize: "12px",
                padding: "10px 0px 10px 0px",
                backgroundColor: "white",
                border: "1px solid lightgray",
                color: "lightgray",
                textTransform: "none",
              }}
              // color="primary"
              fullWidth
              onClick={handleResetSelection}
              disabled={orderedPhotos.length === 0}
            >
              Remove Selected
            </Button>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Button
              variant="contained"
              style={{
                borderRadius: "30px",
                whiteSpace: "nowrap",
                fontSize: "12px",
                backgroundColor: "#FFE500",
                color: "black",
                padding: "10px 0px 10px 0px",
                textTransform: "none",
              }}
              color="success"
              fullWidth
              onClick={handleSubmitSelection}
              disabled={loadingSetPhotos}
            >
              {loadingSetPhotos ? (
                <LoadingIndicator size={24} indicatorColor="white" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
      <CardModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ ...INITIAL_MODAL_STATE })}
      >
        <Stack spacing={2} direction="column">
          <Text variant="h6">
            Are you sure you want to remove this photo from the database?
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              alt="Selected img"
              src={modalState.src}
              style={{
                width: CLICKABLE_IMAGE_DIMENSIONS.WIDTH,
                height: CLICKABLE_IMAGE_DIMENSIONS.HEIGHT,
                objectFit: "cover",
              }}
            />
          </div>
          {errorRemovePhoto && <ErrorMessage />}
          <Button
            variant="contained"
            type="submit"
            disabled={loadingRemovePhoto}
            onClick={() => handleRemovePhoto(modalState.fileName)}
          >
            {loadingRemovePhoto ? (
              <LoadingIndicator size={24} indicatorColor="white" />
            ) : (
              "Confirm"
            )}
          </Button>
        </Stack>
      </CardModal>
    </>
  );
};
