import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { notification } from "antd";
import {
  CardModal,
  ErrorMessage,
  InputField,
  LoadingIndicator,
  Text,
} from "components/common";
import {
  useGetListing,
  useGetListings,
  useRemoveListing,
  useUpdateListing,
} from "hooks";
import React, { useEffect, useState } from "react";
import { DetailedListing } from "types/entities";
import { RemoveListingFunction } from "types/functions";
import { formatListingAddress } from "utils";
import Box from "@mui/material/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import { MapOutlined, MapRounded, Padding } from "@mui/icons-material";
import { MapIcon } from "../../../assets/svgs/MapIcon";
const INITIAL_FORM: DetailedListing = {
  id: "",
  address_line_1: "",
  address_line_2: "",
  approved: false,
  assigned_schools_refresh_date: "",
  availability: "",
  awaiting_approval: false,
  buy_now_price: 0,
  city: "",
  closing_date: "",
  description: "",
  has_ac: false,
  has_pool: false,
  home_type: "",
  is_pending: false,
  last_date_of_occupancy: "",
  latitude: 0,
  list_price: 0,
  listing_date: "",
  longitude: 0,
  lot_size_sqft: 0,
  notification_sent: false,
  num_baths: "",
  num_beds: 0,
  occupied: false,
  photo_urls: [],
  roof_age: "",
  roof_type: "",
  seller_id: "",
  showing_duration_minutes: 0,
  showing_step_minutes: 0,
  sqft: 0,
  state: "",
  virtual_walkthrough_url: "",
  year_built: 0,
  zip: "",
  features: {},
  conditions: {},
  defects: {},
  flood_info: {},
  more_info: {},
  exclusions: {
    id: "",
    listing_id: "",
    has_exclusions: false,
    exclusion_1: null,
    exclusion_2: null,
    exclusion_3: null,
    exclusion_4: null,
    exclusion_5: null,
  },
};

const INITIAL_MODAL_STATE = {
  formattedAddress: "",
  isOpen: false,
};

interface OwnProps {
  listing: DetailedListing;
  loadingRemoveListing: boolean;
  errorRemoveListing: boolean;
  removeListing: RemoveListingFunction;
  setCheck?: any;
  setOpenDrawer?: any;
  setApiCalled?: any;
  setKeyword?: any;
  setPendingCheck?: any;
  setFilters?: any;
}

enum ListingStatus {
  AWAITING_APPROVAL = "awaiting_approval",
  APPROVED = "approved",
  DENIED = "denied",
}

export const ManageListingsUpdateForm: React.FC<OwnProps> = ({
  listing,
  loadingRemoveListing,
  errorRemoveListing,
  removeListing,
  setCheck,
  setOpenDrawer,
  setApiCalled,
  setKeyword,
  setPendingCheck,
  setFilters,
}) => {
  const { updateListing } = useUpdateListing();
  const { getListing } = useGetListing();
  const { getListings } = useGetListings();
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [modalState, setModalState] = useState({ ...INITIAL_MODAL_STATE });
  const gridItemStyle: React.CSSProperties = { width: "100%", padding: "4px" };
  const [api, contextHolder] = notification.useNotification();

  const handleUpdateForm = (
    key: keyof DetailedListing,
    val: string,
    type: "string" | "number" = "string"
  ) => {
    let newVal;
    if (type === "number") newVal = parseFloat(val);
    else newVal = val;
    setForm({ ...form, [key]: newVal });
  };

  const handleRemoveListing = (listing: DetailedListing) => {
    removeListing({ listingId: listing.id }).then(() => getListings());
  };

  const handleChangeListingStatus = (
    option: ListingStatus,
    checked: boolean
  ): void => {
    const newStatus = {
      awaiting_approval: false,
      approved: false,
    };
    switch (option) {
      case ListingStatus.AWAITING_APPROVAL:
        newStatus.awaiting_approval = true;
        newStatus.approved = false;
        break;
      case ListingStatus.APPROVED:
        newStatus.awaiting_approval = false;
        newStatus.approved = true;
        break;
      case ListingStatus.DENIED:
        newStatus.awaiting_approval = false;
        newStatus.approved = false;
        break;
      default:
        alert("handleChangeListingStatus: invalid option has been selected");
        break;
    }
    setForm({ ...form, ...newStatus });
  };

  const determineListingStatus = (
    listing: DetailedListing,
    status: ListingStatus
  ): boolean => {
    let isGivenStatus = false;
    switch (status) {
      case ListingStatus.AWAITING_APPROVAL:
        if (listing.awaiting_approval && !listing.approved)
          isGivenStatus = true;
        break;
      case ListingStatus.APPROVED:
        if (!listing.awaiting_approval && listing.approved)
          isGivenStatus = true;
        break;
      case ListingStatus.DENIED:
        if (!listing.awaiting_approval && !listing.approved)
          isGivenStatus = true;
        break;
      default:
        break;
    }
    return isGivenStatus;
  };

  const handleUpdateListing = () => {
    updateListing({ listing: form }).then(() => {
      api.success({
        message: `Successfully updated listing`,
        placement: "bottomRight",
      });
      setCheck(true);
      setOpenDrawer(false);
      setApiCalled(true);
      setKeyword("");
      setPendingCheck(false);
      setFilters([{ column: "", operator: "", value: "" }]);
      return getListing({ listingId: listing.id });
    });
  };

  const generateLabel = (text: string) => (
    <Text variant="h5" fontWeight={600} marginBottom="20px">
      {text}
    </Text>
  );

  useEffect(() => setForm({ ...INITIAL_FORM, ...listing }), [listing]);

  useEffect(() => {
    if (!loadingRemoveListing && !errorRemoveListing && modalState.isOpen) {
      setModalState({ ...INITIAL_MODAL_STATE });
    }
  }, [loadingRemoveListing, errorRemoveListing]);

  return (
    <>
      <Text
        sx={{
          fontFamily: "PlusJakartaSans-Regular",
          fontSize: "14px",
          fontWeight: "600",
        }}
        marginBottom="8px"
      >
        {" "}
        Status
      </Text>
      {/* <div
        style={{ fontSize: "14px", fontFamily: "PlusJakartaSans-Regular" }}
      ></div> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mb: 2,
          bgcolor: "background.paper",
          width: "50%",
          borderRadius: 1,
        }}
      >
        {" "}
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleChangeListingStatus(
                    ListingStatus.APPROVED,
                    e.target.checked
                  )
                }
                checked={determineListingStatus(form, ListingStatus.APPROVED)}
                sx={{
                  background: "transparent",
                  border: 0,
                  "&.Mui-checked": {
                    color: "#ABD584",
                    "& .MuiSvgIcon-root": {
                      backgroundColor: "transparent",
                      border: "none",
                    },
                  },
                }}
              />
            }
            label="Approved"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "PlusJakartaSans-Regular",
              },
            }}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleChangeListingStatus(
                    ListingStatus.DENIED,
                    e.target.checked
                  )
                }
                checked={determineListingStatus(form, ListingStatus.DENIED)}
                sx={{
                  background: "transparent",
                  border: 0,
                  "&.Mui-checked": {
                    color: "#ABD584",
                    "& .MuiSvgIcon-root": {
                      backgroundColor: "transparent",
                      border: "none",
                    },
                  },
                }}
              />
            }
            label="Denied"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "PlusJakartaSans-Regular",
              },
            }}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleChangeListingStatus(
                    ListingStatus.AWAITING_APPROVAL,
                    e.target.checked
                  )
                }
                checked={determineListingStatus(
                  form,
                  ListingStatus.AWAITING_APPROVAL
                )}
                sx={{
                  background: "transparent",
                  border: 0,
                  "&.Mui-checked": {
                    color: "#ABD584",
                    "& .MuiSvgIcon-root": {
                      backgroundColor: "transparent",
                      border: "none",
                    },
                  },
                }}
              />
            }
            label=" Pending"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "PlusJakartaSans-Regular",
              },
            }}
          />
        </div>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          // marginTop: "-40px",

          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Text
          sx={{
            fontFamily: "PlusJakartaSans-Regular",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Listing Price
        </Text>
        <div>
          <InputField
            placeholder="Listing Price"
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                fontWeight: "600",
              },
            }}
            fullWidth
            // label="List Price"
            value={form.list_price || ""}
            onChange={(val) => handleUpdateForm("list_price", val, "number")}
          />
        </div>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Text
          sx={{
            fontFamily: "PlusJakartaSans-Regular",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Description
        </Text>
        <div>
          <InputField
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                fontWeight: "600",
              },
            }}
            fullWidth
            placeholder="Add description"
            multiline
            maxRows={5}
            value={form.description || ""}
            onChange={(val) => handleUpdateForm("description", val)}
          />
        </div>
      </Box>
      {/* <div style={gridItemStyle}>{generateLabel("Other Data")}</div> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: { xs: "6px", sm: "10px" },
          // mb: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <div>
          <InputField
            placeholder="Bedrooms"
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: { xs: "10px", sm: "12px" },
                fontWeight: "600",
              },
            }}
            fullWidth
            label={
              <Text
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "600",
                  fontFamily: "PlusJakartaSans-Regular",
                }}
              >
                Bedrooms
              </Text>
            }
            type="number"
            value={form.num_beds || ""}
            onChange={(val) => handleUpdateForm("num_beds", val, "number")}
          />
        </div>
        <div>
          <InputField
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                fontWeight: "600",
              },
            }}
            fullWidth
            label={
              <Text
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "600",
                  fontFamily: "PlusJakartaSans-Regular",
                }}
              >
                Bathrooms
              </Text>
            }
            placeholder=" Bathrooms"
            value={form.num_baths || ""}
            type="number"
            onChange={(val) => handleUpdateForm("num_baths", val, "number")}
          />
        </div>
        <div>
          <InputField
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                fontWeight: "600",
              },
            }}
            fullWidth
            label={
              <Text
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "600",
                  fontFamily: "PlusJakartaSans-Regular",
                }}
              >
                Square Ft
              </Text>
            }
            placeholder=" Square Ft"
            value={form.sqft || ""}
            onChange={(val) => handleUpdateForm("sqft", val, "number")}
          />
        </div>
        <div>
          <InputField
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                fontWeight: "600",
              },
            }}
            fullWidth
            label={
              <Text
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "600",
                  fontFamily: "PlusJakartaSans-Regular",
                }}
              >
                Lot Size
              </Text>
            }
            placeholder="Lot Size"
            value={form.lot_size_sqft || ""}
            onChange={(val) => handleUpdateForm("lot_size_sqft", val, "number")}
          />
        </div>
      </Box>
      <div
        style={{
          margin: "20px 0px 20px 0px",
          height: "2px",
          width: "100%",
          backgroundColor: "#E9E9E9",
        }}
      ></div>
      <div
        style={{
          fontWeight: "600",
          fontSize: "12px",
          fontFamily: "PlusJakartaSans-Regular",
        }}
      >
        <p>Address</p>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Text
          sx={{
            fontFamily: "PlusJakartaSans-Regular",
            fontSize: "14px",
            fontWeight: "600",
          }}
          // marginTop="15px"
        >
          Address Line 1
        </Text>
        <div>
          <InputField
            placeholder="Address Line 1"
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                fontWeight: "600",
              },
            }}
            fullWidth
            // label="Address Line 1"
            value={form.address_line_1 || ""}
            onChange={(val) => handleUpdateForm("address_line_1", val)}
          />
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Text
          sx={{
            fontFamily: "PlusJakartaSans-Regular",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Address Line 2
        </Text>
        <div>
          <InputField
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                fontWeight: "600",
              },
            }}
            fullWidth
            // label="Address Line 1"
            // style={{ marginTop: "10px" }}
            value={form.address_line_2 || ""}
            onChange={(val) => handleUpdateForm("address_line_2", val)}
            placeholder="Address Line 2"
          />
        </div>
      </Box>

      <Box
        sx={{
          display: "flex",
          // justifyContent: "space-between",
          gap: "25px",
          alignItems: "center",
          width: "100%",
          // backgroundColor: "red",
        }}
      >
        <Box
          sx={{
            mb: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            width: "33%",
          }}
        >
          <Text
            sx={{
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            City
          </Text>
          <div>
            <InputField
              placeholder="City"
              sx={{
                "& .MuiOutlinedInput-root": {
                  // Styling the border
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                  // On hover
                  "&:hover fieldset": {
                    borderColor: "#D6D6D6",
                  },
                  // On focus
                  "&.Mui-focused fieldset": {
                    borderColor: "#CFCFCF",
                  },
                },
                "& .MuiInputBase-input": {
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "12px",
                  fontWeight: "600",
                },
              }}
              fullWidth
              // label="City"
              value={form.city || ""}
              onChange={(val) => handleUpdateForm("city", val)}
            />
          </div>
        </Box>
        <Box
          sx={{
            mb: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            width: "33%",
          }}
        >
          <Text
            sx={{
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            State
          </Text>{" "}
          <div>
            <InputField
              placeholder="State"
              sx={{
                "& .MuiOutlinedInput-root": {
                  // Styling the border
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                  // On hover
                  "&:hover fieldset": {
                    borderColor: "#D6D6D6",
                  },
                  // On focus
                  "&.Mui-focused fieldset": {
                    borderColor: "#CFCFCF",
                  },
                },
                "& .MuiInputBase-input": {
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "12px",
                  fontWeight: "600",
                },
              }}
              fullWidth
              // label="State"
              value={form.state || ""}
              onChange={(val) => handleUpdateForm("state", val)}
            />
          </div>
        </Box>
        <Box
          sx={{
            mb: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            width: "33%",
          }}
        >
          <Text
            sx={{
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            Zip Code
          </Text>
          <div>
            <InputField
              placeholder="Zip Code"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  // Styling the border
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                  // On hover
                  "&:hover fieldset": {
                    borderColor: "#D6D6D6",
                  },
                  // On focus
                  "&.Mui-focused fieldset": {
                    borderColor: "#CFCFCF",
                  },
                },
                "& .MuiInputBase-input": {
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "12px",
                  fontWeight: "600",
                },
              }}
              // label="Zip"
              value={form.zip || ""}
              onChange={(val) => handleUpdateForm("zip", val)}
            />
          </div>
        </Box>
      </Box>
      <div
        style={{
          margin: "20px 0px 20px 0px",
          height: "2px",
          width: "100%",
          backgroundColor: "#E9E9E9",
        }}
      ></div>

      {/* <div style={gridItemStyle}>{generateLabel("Coordinates")}</div> */}
      <div
        style={{
          fontWeight: "600",
          fontSize: "12px",
          fontFamily: "PlusJakartaSans-Regular",
        }}
      >
        <p>Coordinates</p>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        {" "}
        <Text
          sx={{
            fontFamily: "PlusJakartaSans-Regular",
            fontSize: "14px",
            fontWeight: "600",
          }}
          // marginTop="15px"
        >
          Latitude
        </Text>
        <div>
          <InputField
            placeholder="Latitude"
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                // fontWeight: "600",
              },
            }}
            fullWidth
            // label="Latitude"
            value={form.latitude || ""}
            onChange={(val) => handleUpdateForm("latitude", val, "number")}
          />
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        {" "}
        <Text
          sx={{
            fontFamily: "PlusJakartaSans-Regular",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Longitude
        </Text>
        <div>
          <InputField
            placeholder="Longitude"
            sx={{
              "& .MuiOutlinedInput-root": {
                // Styling the border
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                // On hover
                "&:hover fieldset": {
                  borderColor: "#D6D6D6",
                },
                // On focus
                "&.Mui-focused fieldset": {
                  borderColor: "#CFCFCF",
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "PlusJakartaSans-Regular",
                fontSize: "12px",
                // fontWeight: "600",
              },
            }}
            fullWidth
            // label="Longitude"
            value={form.longitude || ""}
            onChange={(val) => handleUpdateForm("longitude", val, "number")}
          />
        </div>
        <Text
          className="margin-top-10"
          style={{
            fontFamily: "PlusJakartaSans-Regular",
            fontSize: "12px",
          }}
        >
          These values should be handled by google autocomplete. If they are
          missing you should add them manually.
        </Text>
        <Text
          className="margin-top-10"
          style={{ fontFamily: "PlusJakartaSans-Regular", fontSize: "12px" }}
        >
          You can use the following link to approximate the coordinates(right
          click a spot on the map, note (latitude, longitude) and add them here
        </Text>
        <div className="margin-top-10">
          <Button
            // variant="outlined"
            style={{
              backgroundColor: "#FFE500",
              textTransform: "none",
              borderRadius: "30px",
              color: "black",
              // border: "1px solid #DDDDDD",
              padding: "10px 20px 10px 20px",
              height: "35px",
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: "12px",
            }}
            href="https://www.google.com/maps"
            startIcon={<MapIcon />}
          >
            Google Maps
          </Button>
        </div>
      </Box>

      <Grid
        container
        justifyContent="flex-end"
        className="margin-top-10"
        spacing={2}
      >
        <Grid item xs={6}>
          <Button
            style={{
              backgroundColor: "transparent",
              borderRadius: "40px",
              border: "1px solid #d1d1d1",
              color: "black",
              height: "50px",
              textTransform: "none",
            }}
            fullWidth
            onClick={() =>
              setModalState({
                isOpen: true,
                formattedAddress: formatListingAddress(listing),
              })
            }
          >
            Remove Listing
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            style={{
              backgroundColor: "#FFE500",
              borderRadius: "40px",
              color: "black",
              height: "50px",
              textTransform: "none",
            }}
            fullWidth
            onClick={handleUpdateListing}
          >
            Update Changes
          </Button>
        </Grid>
      </Grid>

      {/* Old Design */}
      {/* <Grid container spacing={2} alignItems="flex-start">
        {contextHolder}
        <Grid xs={4} item>
          <div style={gridItemStyle}>{generateLabel("Description")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              multiline
              maxRows={5}
              label="Description"
              value={form.description || ""}
              onChange={(val) => handleUpdateForm("description", val)}
            />
          </div>

          <div style={gridItemStyle}>{generateLabel("Approval Status")}</div>
          <RadioGroup>
            <div style={gridItemStyle}>
              <FormControlLabel
                control={
                  <Radio
                    onChange={(e) =>
                      handleChangeListingStatus(
                        ListingStatus.APPROVED,
                        e.target.checked
                      )
                    }
                    checked={determineListingStatus(
                      form,
                      ListingStatus.APPROVED
                    )}
                  />
                }
                label="Approved"
              />
            </div>
            <div style={gridItemStyle}>
              <FormControlLabel
                control={
                  <Radio
                    onChange={(e) =>
                      handleChangeListingStatus(
                        ListingStatus.DENIED,
                        e.target.checked
                      )
                    }
                    checked={determineListingStatus(form, ListingStatus.DENIED)}
                  />
                }
                label="Denied"
              />
            </div>
            <div style={gridItemStyle}>
              <FormControlLabel
                control={
                  <Radio
                    onChange={(e) =>
                      handleChangeListingStatus(
                        ListingStatus.AWAITING_APPROVAL,
                        e.target.checked
                      )
                    }
                    checked={determineListingStatus(
                      form,
                      ListingStatus.AWAITING_APPROVAL
                    )}
                  />
                }
                label="Awaiting Approval / Pending"
              />
            </div>
          </RadioGroup>
        </Grid>

        <Grid xs={4} item>
          <div style={gridItemStyle}>{generateLabel("Address")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Address Line 1"
              value={form.address_line_1 || ""}
              onChange={(val) => handleUpdateForm("address_line_1", val)}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Address Line 1"
              value={form.address_line_2 || ""}
              onChange={(val) => handleUpdateForm("address_line_2", val)}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="City"
              value={form.city || ""}
              onChange={(val) => handleUpdateForm("city", val)}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="State"
              value={form.state || ""}
              onChange={(val) => handleUpdateForm("state", val)}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Zip"
              value={form.zip || ""}
              onChange={(val) => handleUpdateForm("zip", val)}
            />
          </div>

          <div style={gridItemStyle}>{generateLabel("Other Data")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Beds"
              type="number"
              value={form.num_beds || ""}
              onChange={(val) => handleUpdateForm("num_beds", val, "number")}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Baths"
              value={form.num_baths || ""}
              type="number"
              onChange={(val) => handleUpdateForm("num_baths", val, "number")}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Square Ft"
              value={form.sqft || ""}
              onChange={(val) => handleUpdateForm("sqft", val, "number")}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Lot Size"
              value={form.lot_size_sqft || ""}
              onChange={(val) =>
                handleUpdateForm("lot_size_sqft", val, "number")
              }
            />
          </div>
        </Grid>

        <Grid xs={4} item>
          <div style={gridItemStyle}>{generateLabel("List Price")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="List Price"
              value={form.list_price || ""}
              onChange={(val) => handleUpdateForm("list_price", val, "number")}
            />
          </div>

          <div style={gridItemStyle}>{generateLabel("Coordinates")}</div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Latitude"
              value={form.latitude || ""}
              onChange={(val) => handleUpdateForm("latitude", val, "number")}
            />
          </div>
          <div style={gridItemStyle}>
            <InputField
              fullWidth
              label="Longitude"
              value={form.longitude || ""}
              onChange={(val) => handleUpdateForm("longitude", val, "number")}
            />
          </div>
          <Text>
            These values should be handled by google autocomplete. If they are
            missing you should add them manually.
          </Text>
          <Text className="margin-top-10">
            You can use the following link to approximate the coordinates(right
            click a spot on the map, note (latitude, longitude) and add them
            here)
          </Text>
          <div className="margin-top-10">
            <Link
              href="https://www.google.com/maps"
              underline="hover"
              target="_blank"
            >
              https://www.google.com/maps
            </Link>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        className="margin-top-10"
        spacing={2}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={() =>
              setModalState({
                isOpen: true,
                formattedAddress: formatListingAddress(listing),
              })
            }
          >
            Remove Listing
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" fullWidth onClick={handleUpdateListing}>
            Update
          </Button>
        </Grid>
      </Grid> */}
      {/* Old Design */}
      <CardModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ ...INITIAL_MODAL_STATE })}
      >
        <Stack spacing={2} direction="column">
          <Text variant="h6">
            Are you sure you want to remove this listing from the database?
          </Text>
          <Text variant="subtitle1">{modalState.formattedAddress}</Text>
          {errorRemoveListing && <ErrorMessage />}
          <Button
            variant="contained"
            type="submit"
            disabled={loadingRemoveListing}
            onClick={() => handleRemoveListing(listing)}
          >
            {loadingRemoveListing ? (
              <LoadingIndicator size={24} indicatorColor="white" />
            ) : (
              "Confirm"
            )}
          </Button>
        </Stack>
      </CardModal>
    </>
  );
};
