import React, { FormEvent, useState, useEffect, useRef } from "react";

import { useCreateUser, useGetUsers, useUpdateUser } from "hooks";
import { UserRole, VendorRole } from "types/entities";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
} from "@mui/material";
import {
  Button,
  CheckboxGroup,
  InputField,
  RadioGroup,
  Text,
} from "components/common";
import { ManageUsersTable } from "components/user";
import { MAX_GRID_ITEM_HEIGHT } from "consts/dimensions";
import { ViewWrapper } from "components/wrappers";
import { message, notification } from "antd";
import CrossDrawerIcon from "../../assets/images/CrossDrawerIcon.svg";
import createicon from "../../assets/images/createicon.svg";
import CreateUserIcon from "../../assets/images/CreateUserIcon.svg";
import { TableData, TableDataItem, TableDataItemValue } from "types/components";
import { UsersInStore } from "types/store";
import { Label, SearchOutlined } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useUserStore } from "store";
import { useGetUser } from "hooks";
import { User } from "types/entities";
import { InputLabel } from "@mui/material";
import Modal from "@mui/material/Modal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import closeIcon from "../../assets/images/closeIcon.svg";
import userIcon from "../../assets/images/userIcon.svg";
import frame from "../../assets/images/Frame 538.svg";
import editIconFill from "../../assets/images/editIconFill.svg";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { INITIAL_USERS_TABLE_DATA } from "components/user/ManageUsersTable/consts";
import { zip } from "lodash";
import { updateUserProfilePicture } from "services/UserService";
import { UseUpdateUserProfilePicture } from "hooks/useUpdateProfilePicture";

interface Filter {
  column: string;
  operator: string;
  value: string;
}

export const ManageUsers: React.FC = () => {
  const { getUsers } = useGetUsers();
  const { getUser } = useGetUser();
  const { updateUser } = useUpdateUser();
  const {
    createUser,
    loading: createUserLoading,
    error: createUserError,
  } = useCreateUser();
  const { users } = useUserStore();
  const [usersTableData, setUsersTableData] = useState<TableData>({
    ...INITIAL_USERS_TABLE_DATA,
  });

  // console.log(usersTableData, "usersTableData");
  const [givenName, setGivenName] = useState<string>("");
  const [familyName, setFamilyName] = useState<string>("");
  const [telephoneNumber, setTelephoneNumber] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [selectedProfilePic, setSelectedProfilePic] = useState<File | null>(
    null
  );
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [showUserRoles, setShowUserRoles] = useState<string>("");
  const [userRoles, setUserRoles] = useState<Set<UserRole>>(
    new Set([UserRole.CUSTOMER])
  );
  const [showVendorRoles, setShowVendorRoles] = useState<string>("");
  const [vendorRole, setVendorRole] = useState<VendorRole>(
    VendorRole.INSPECTOR
  );
  const [api, contextHolder] = notification.useNotification();
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [openUpdateUserDrawer, setOpenUpdateUserDrawer] = useState(false);

  const [keyword, setKeyword] = useState<string>("");
  const [modalTabs, setModalTabs] = useState<string>("information");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedListing, setSelectedListing] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const userFilterButton = useRef(null);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });

  const mapUsersToTableData = (
    data: UsersInStore,
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data?.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof UsersInStore;
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const handleOpen = () => {
    if (userFilterButton.current) {
      const rect = userFilterButton;
      // @ts-ignore
      const top = rect.current["offsetTop"];
      // @ts-ignore
      const left = rect.current["offsetLeft"];
      setPosition({
        top: top + 50,
        left: left - 180,
      });
    }
    setOpen(true);
  };
  const [filters, setFilters] = useState<Filter[]>([
    { column: "", operator: "", value: "" },
  ]);

  const toggleDrawer = (openUserDrawer: any) => () => {
    setOpenUserDrawer(openUserDrawer);
    setSelectedListing("");
    setGivenName("");
    setFamilyName("");
    setTelephoneNumber("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("");
    setZipCode("");
    setShowUserRoles("");
    setShowVendorRoles("");
  };
  const toggleUpdateDrawer = (openUserDrawer: any) => () => {
    setOpenUpdateUserDrawer(openUserDrawer);
    setSelectedListing("");
    setGivenName("");
    setFamilyName("");
    setEmail("");
    setTelephoneNumber("");
    setProfilePicture("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("");
    setZipCode("");
    setShowUserRoles("");
    setShowVendorRoles("");
  };

  useEffect(() => {
    mapUsersToTableData(users, INITIAL_USERS_TABLE_DATA, setUsersTableData);
  }, [users]);

  // console.log(usersTableData, "usersTableData");

  useEffect(() => {
    if (createUserError) {
      api.error({
        message: `Error while adding user`,
        placement: "bottomRight",
      });
    }
  }, [createUserError]);

  const handleAddUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.length > 0 && givenName.length > 0 && familyName.length > 0) {
      const res = await createUser({
        email,
        familyName,
        givenName,
        roles: userRoles,
        vendorRole,
      });

      if (!res) return;
      api.success({
        message: `Successfully added user`,
        placement: "bottomRight",
      });

      setOpenUserDrawer(false);
      setRefresh(true);
      setFamilyName("");
      setGivenName("");
      setTelephoneNumber("");
      setEmail("");
    }
  };

  // Update a specific filter
  const handleUpdateFilter = (
    index: number,
    field: keyof Filter,
    value: string
  ) => {
    const updatedFilters = [...filters];
    updatedFilters[index][field] = value;
    setFilters(updatedFilters);
  };

  // Remove a specific filter row
  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleClose = () => setOpen(false);
  // Clear all filters
  const handleCleanAll = () => {
    setFilters([{ column: "", operator: "", value: "" }]);
  };

  const handleAddFilter = () => {
    setFilters([...filters, { column: "", operator: "", value: "" }]);
  };

  // const fetchUserDetails = async (selectedListing: string) => {
  //   const user = users?.find((user) => user.id === selectedListing);
  //   if (user) {
  //     const userDetail: {} = await getUser({ userId: selectedListing });
  //     const arr: User[] = [userDetail];

  //     console.log(userDetail.givenName, "arr");
  //   } else alert(`Can't find a listing with id: ${selectedListing}`);
  // };

  useEffect(() => {
    if (!open) {
      getUsers({ filters });
    }
  }, [open]);
  const fetchUserDetails = async (selectedListing: string) => {
    const user = useUserStore
      .getState()
      .users?.find((user) => user.id === selectedListing);

    if (user) {
      try {
        const userDetail = await useUserStore
          .getState()
          .getUser({ userId: selectedListing });

        if (userDetail && "givenName" in userDetail) {
          const arr: User[] = [userDetail];
          console.log(userDetail, "userDetail=======>");
          setGivenName(userDetail?.givenName || "");
          setFamilyName(userDetail?.familyName || "");
          setTelephoneNumber(userDetail?.telephoneNumber || "");
          setDateOfBirth(userDetail?.dateOfBirth?.split("T")[0] || "");
          setEmail(userDetail?.emailAddress || "");
          setCity(userDetail?.city || "");
          setState(userDetail?.state || "");
          setZipCode(userDetail?.zip || "");
          setAddressLine1(userDetail?.addressLine1 || "");
          setAddressLine2(userDetail?.addressLine2 || "");
          setProfilePicture(userDetail?.profilePicture || "");
          setShowUserRoles(
            userDetail?.isVendor
              ? "Vendor"
              : userDetail?.isAdmin
              ? "Admin"
              : userDetail?.isListingSpecialist
              ? "Specialist"
              : userDetail?.isCustomer
              ? "Customer"
              : ""
          );
          setShowVendorRoles(userDetail?.vendorRole ?? "");
        } else {
          console.error("Invalid user details:", userDetail);
          alert("Received invalid user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    } else {
      alert(`Can't find a listing with id: ${selectedListing}`);
    }
  };

  const updateUserDetails = async () => {
    try {
      const userId = selectedListing;
      const demographics = {
        givenName: givenName,
        familyName: familyName,
        telephoneNumber: telephoneNumber,
        city: city,
        dateOfBirth: dateOfBirth,
        state: state,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        zip: zipCode,
      };

      const result = await updateUser({
        userId,
        demographics,
      });
      setRefresh(true);
      setOpenUpdateUserDrawer(false);
      setSelectedListing("");
      setGivenName("");
      setFamilyName("");
      setTelephoneNumber("");
      setCity("");
      setDateOfBirth("");
      setState("");
      setAddressLine1("");
      setAddressLine2("");
      setZipCode("");
    } catch (error) {
      console.error("Error updating demographics:", error);
    }
  };

  const updateUserProfile = async (file: File) => {
    console.log(file, "file====");

    try {
      const formData = new FormData();
      formData.append("userId", selectedListing);
      formData.append("profile_pic", file);
      const result = await updateUserProfilePicture(formData);
      // const userId = selectedListing;
      // const result = await updateUserProfilePicture({
      //   userId,
      //   profile_pic: file,
      // });

      setRefresh(true);
      setOpenUpdateUserDrawer(false);

      if (result && "profile_pic" in result) {
        console.log(result, "result");
        if (result && typeof result.profile_pic === "string") {
          setProfilePicture(result.profile_pic || "");
        }
      }
    } catch (error) {
      console.error("Error In Updating Profile:", error);
    }
  };

  useEffect(() => {
    if (!selectedListing) return;
    fetchUserDetails(selectedListing);
  }, [selectedListing]);

  useEffect(() => {
    // if (keyword) {
    getUsers({ keyword });
    // }
  }, [keyword]);

  useEffect(() => {
    if (refresh) {
      getUsers({});
    }
    setRefresh(false);
  }, [refresh]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      updateUserProfile(file);
    }
  };
  const handleDateChange = (event: any) => {
    setDateOfBirth(event);
  };

  return (
    <ViewWrapper title="Manage users">
      {contextHolder}
      <Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { sm: "center" },
            justifyContent: "space-between",
            marginBottom: "20px",
            gap: { xs: "10px", md: "0px" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              gap: { xs: "5px", md: "10px" },
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <TextField
              sx={{ width: "100%", maxWidth: "400px" }}
              id="standard-bare"
              defaultValue="Search"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                ),
                style: {
                  borderRadius: "30px",
                  height: "35px",
                  fontFamily: "PlusJakartaSans-Regular",
                },
              }}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            {/* Desktop Filter Button */}
            <Button
              variant="outlined"
              sx={{
                display: { xs: "none", sm: "flex" },
                backgroundColor: "black",
                textTransform: "none",
                borderRadius: "30px",
                color: "white",
                border: "1px solid #DDDDDD",
                fontFamily: "PlusJakartaSans-Regular",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                  border: "1px solid #DDDDDD",
                },
              }}
              startIcon={<FilterListIcon />}
              onClick={handleOpen}
            >
              Filter
            </Button>
            <Box ref={userFilterButton} sx={{ backgroundColor: "green" }}></Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {/* Mobile Filter Button */}
            <Button
              variant="outlined"
              sx={{
                width: "fit-content",
                backgroundColor: "black",
                textTransform: "none",
                borderRadius: "30px",
                color: "white",
                border: "1px solid #DDDDDD",
                display: { xs: "flex", sm: "none" },
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                  border: "1px solid #DDDDDD",
                },
              }}
              onClick={handleOpen}
            >
              <FilterListIcon />
            </Button>

            {/*Desktop User button */}
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "black",
                textTransform: "none",
                whiteSpace: "nowrap",
                borderRadius: "30px",
                color: "white",
                border: "1px solid #DDDDDD",
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
              }}
              startIcon={
                <img
                  src={CreateUserIcon}
                  alt="Create User Icon"
                  style={{ height: 20 }}
                />
              }
              onClick={toggleDrawer(true)}
            >
              Create User
            </Button>

            {/*Mobile User button */}
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "black",
                textTransform: "none",
                borderRadius: "30px",
                color: "white",
                border: "1px solid #DDDDDD",
                display: { xs: "flex", sm: "none" },
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={toggleDrawer(true)}
            >
              <img
                src={CreateUserIcon}
                alt="Create User Icon"
                style={{ width: 25 }}
              />
            </Button>
          </Box>
        </Box>
        <Text marginTop="10px">{users?.length} Results</Text>

        <ManageUsersTable
          setOpenUpdateUserDrawer={setOpenUpdateUserDrawer}
          setSelectedListing={setSelectedListing}
        />
        {/* User Create */}
        <Drawer
          anchor="right"
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: "#11111180 ",
                backdropFilter: "blur(2px)",
                // zIndex: "99999",
              },
            },
          }}
          open={openUserDrawer}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "16px",
              height: "97.5vh",
              margin: "10px",
              padding: "20px",

              width: { xs: "94%", sm: "75%", md: "55%", lg: "35%" },
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              padding: "25px",
              typography: "body1",
            }}
          >
            <Box
              marginBottom="20px"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
                // paddingLeft={"10px"}
              >
                Create user
              </Text>
              <img
                src={CrossDrawerIcon}
                style={{ height: "30px", cursor: "pointer" }}
                onClick={toggleDrawer(false)}
              />
            </Box>

            <form onSubmit={handleAddUser}>
              <Stack spacing={2}>
                <InputField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      // Styling the border
                      "& fieldset": {
                        borderColor: "#E0E0E0",
                      },
                      // On hover
                      "&:hover fieldset": {
                        borderColor: "#D6D6D6",
                      },
                      // On focus
                      "&.Mui-focused fieldset": {
                        borderColor: "#CFCFCF",
                      },
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "PlusJakartaSans-Regular",
                      fontSize: "12px",
                      fontWeight: "600",
                    },
                  }}
                  style={{ marginBottom: "10px" }}
                  label={
                    <span
                      style={{
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      First Name*
                    </span>
                  }
                  type="text"
                  variant="outlined"
                  required
                  value={givenName}
                  onChange={setGivenName}
                />
                <InputField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      // Styling the border
                      "& fieldset": {
                        borderColor: "#E0E0E0",
                      },
                      // On hover
                      "&:hover fieldset": {
                        borderColor: "#D6D6D6",
                      },
                      // On focus
                      "&.Mui-focused fieldset": {
                        borderColor: "#CFCFCF",
                      },
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "PlusJakartaSans-Regular",
                      fontSize: "12px",
                      fontWeight: "600",
                    },
                  }}
                  style={{ marginBottom: "10px" }}
                  label={
                    <span
                      style={{
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Last Name*
                    </span>
                  }
                  type="text"
                  variant="outlined"
                  required
                  value={familyName}
                  onChange={setFamilyName}
                />

                {selectedListing ? (
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Telephone Number*
                      </span>
                    }
                    type="text"
                    variant="outlined"
                    required
                    value={telephoneNumber}
                    onChange={setTelephoneNumber}
                  />
                ) : (
                  <>
                    <InputField
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // Styling the border
                          "& fieldset": {
                            borderColor: "#E0E0E0",
                          },
                          // On hover
                          "&:hover fieldset": {
                            borderColor: "#D6D6D6",
                          },
                          // On focus
                          "&.Mui-focused fieldset": {
                            borderColor: "#CFCFCF",
                          },
                        },
                        "& .MuiInputBase-input": {
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "12px",
                          fontWeight: "600",
                        },
                      }}
                      style={{ marginBottom: "10px" }}
                      label={
                        <span
                          style={{
                            fontFamily: "PlusJakartaSans-Regular",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Email Address*
                        </span>
                      }
                      type="email"
                      variant="outlined"
                      required
                      value={email}
                      onChange={setEmail}
                    />
                    <CheckboxGroup<UserRole>
                      label="Choose user roles"
                      onChange={setUserRoles}
                      options={[
                        {
                          label: "Listing specialist",
                          value: UserRole.LISTING_SPECIALIST,
                        },
                        {
                          label: "Administrator",
                          value: UserRole.ADMINISTRATOR,
                        },
                        {
                          label: "Vendor",
                          value: UserRole.VENDOR,
                        },
                      ]}
                      value={userRoles}
                    />
                    {userRoles.has(UserRole.VENDOR) ? (
                      <RadioGroup<VendorRole>
                        onChange={setVendorRole}
                        value={vendorRole}
                        label="Vendor role"
                        name="vendor-role"
                        options={[
                          {
                            label: "Inspector",
                            value: VendorRole.INSPECTOR,
                          },
                          {
                            label: "Photographer",
                            value: VendorRole.PHOTOGRAPHER,
                          },
                          {
                            label: "Title company",
                            value: VendorRole.TITLE_COMPANY,
                          },
                        ]}
                      />
                    ) : null}
                  </>
                )}

                {selectedListing ? (
                  <Button
                    style={{
                      backgroundColor: "#FFE500",
                      borderRadius: "40px",
                      color: "black",
                      height: "35px",
                      width: "fit-content",
                      padding: "0px 15px 0px 15px",
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      textTransform: "none",
                    }}
                    // type="submit"
                    // disabled={createUserLoading}
                    onClick={updateUserDetails}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#FFE500",
                      borderRadius: "40px",
                      color: "black",
                      height: "35px",
                      width: "fit-content",
                      padding: "0px 15px 0px 15px",
                      fontSize: "12px",
                      display: "flex",
                      gap: "5px",
                      whiteSpace: "nowrap",
                      textTransform: "none",
                    }}
                    type="submit"
                    disabled={createUserLoading}
                  >
                    <img src={createicon} />
                    <span> Create User</span>
                  </Button>
                )}
              </Stack>
            </form>
          </Box>
        </Drawer>

        {/* User update */}

        <Drawer
          anchor="right"
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: "#11111180 ",
                backdropFilter: "blur(2px)",
                // zIndex: "99999",
              },
            },
          }}
          open={openUpdateUserDrawer}
          onClose={toggleUpdateDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "16px",
              height: "97.5vh",
              margin: "10px",
              padding: "20px",

              width: { xs: "94%", sm: "75%", md: "55%", lg: "35%" },
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              padding: "25px",
              typography: "body1",
            }}
          >
            {/* Update Modal Header */}
            <Box
              marginBottom="20px"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                Update user
              </Text>
              <img
                src={CrossDrawerIcon}
                style={{ height: "30px", cursor: "pointer" }}
                onClick={toggleUpdateDrawer(false)}
              />
            </Box>
            {/* Update Modal Tab Button */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "45px",
                gap: "10px",
                borderBottom: "2px solid lightgray",
              }}
            >
              <Button
                sx={{
                  color: modalTabs == "information" ? "black" : "lightgray",
                  borderBottom:
                    modalTabs == "information" ? "2px solid black" : "",
                  borderRadius: 0,
                  marginBottom: "-2px",
                }}
                onClick={() => setModalTabs("information")}
              >
                Information
              </Button>
              <Button
                sx={{
                  color: modalTabs == "address" ? "black" : "lightgray",
                  borderBottom: modalTabs == "address" ? "2px solid black" : "",
                  borderRadius: 0,
                  marginBottom: "-2px",
                }}
                onClick={() => setModalTabs("address")}
              >
                Address
              </Button>
            </Box>
            {/* Update Modal Form */}
            <form onSubmit={updateUserDetails}>
              {modalTabs == "information" ? (
                // Infromation Form
                <Stack spacing={1}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        backgroundColor: "#ECECEC",
                        width: "25%",
                        aspectRatio: "1/1",
                        borderRadius: "50%",
                      }}
                    >
                      {profilePicture ? (
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            aspectRatio: "1/1",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={profilePicture}
                            alt=""
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </Box>
                      ) : (
                        <img src={userIcon} alt="" style={{ width: "50%" }} />
                      )}
                      {/* Picture Edit Button */}
                      <Box
                        sx={{
                          position: "absolute",
                          right: "-5px",
                          bottom: "-5px",
                          width: "35%",
                        }}
                      >
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            aspectRatio: "1/1",
                            cursor: "pointer",
                          }}
                          htmlFor="profile-picture"
                        >
                          {/* <InputField
                            id="profile-picture"
                            type="file"
                            inputProps={{ accept: "image/*" }}
                            onChange={setProfilePicture}
                            sx={{ display: "none" }}
                          /> */}
                          <input
                            id="profile-picture"
                            type="file"
                            // inputProps={{ accept: "image/*" }}
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "yellow",
                              borderRadius: "50%",
                              width: "80%",
                              aspectRatio: "1/1",
                            }}
                          >
                            <img
                              src={editIconFill}
                              alt=""
                              style={{ width: "50%" }}
                            />
                          </Box>
                        </label>
                      </Box>
                    </Box>
                  </Box>
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        First Name*
                      </span>
                    }
                    type="text"
                    variant="outlined"
                    required
                    value={givenName}
                    onChange={setGivenName}
                  />
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Last Name*
                      </span>
                    }
                    type="text"
                    variant="outlined"
                    required
                    value={familyName}
                    onChange={setFamilyName}
                  />
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    disabled
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Email Address*
                      </span>
                    }
                    type="email"
                    variant="outlined"
                    required
                    value={email}
                    onChange={setEmail}
                  />
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#E0E0E0",
                        },
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    // disabled
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Date of Birth*
                      </span>
                    }
                    type="date"
                    variant="outlined"
                    required
                    value={dateOfBirth}
                    onChange={setDateOfBirth}
                  />
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Telephone Number*
                      </span>
                    }
                    type="text"
                    variant="outlined"
                    required
                    value={telephoneNumber}
                    onChange={setTelephoneNumber}
                  />

                  {/* <CheckboxGroup<UserRole>
                    label="Choose user roles"
                    onChange={setUserRoles}
                    options={[
                      {
                        label: "Listing specialist",
                        value: UserRole.LISTING_SPECIALIST,
                      },
                      {
                        label: "Administrator",
                        value: UserRole.ADMINISTRATOR,
                      },
                      {
                        label: "Vendor",
                        value: UserRole.VENDOR,
                      },
                    ]}
                    value={userRoles}
                  /> */}
                  {showUserRoles && (
                    <>
                      <Text sx={{ fontSize: "14px", fontWeight: "600" }}>
                        User Role*
                      </Text>
                      <Box sx={{ fontSize: "14px" }}>
                        <Checkbox
                          checked={true}
                          sx={{
                            "&.Mui-checked": {
                              color: "#ABD584",
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                border: "none",
                              },
                            },
                          }}
                        />
                        {showUserRoles}
                      </Box>
                    </>
                  )}

                  {showUserRoles == "Vendor" ? (
                    <>
                      <Text sx={{ fontSize: "14px", fontWeight: "600" }}>
                        Vendor Role*
                      </Text>
                      <Box sx={{ fontSize: "14px" }}>
                        <Checkbox
                          checked={true}
                          sx={{
                            "&.Mui-checked": {
                              color: "#ABD584",
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                border: "none",
                              },
                            },
                          }}
                        />
                        {showVendorRoles}
                      </Box>
                    </>
                  ) : null}

                  {/* {userRoles.has(UserRole.VENDOR) ? (
                    <RadioGroup<VendorRole>
                      onChange={setVendorRole}
                      value={vendorRole}
                      label="Vendor role"
                      name="vendor-role"
                      disable={true}
                      options={[
                        {
                          label: "Inspector",
                          value: VendorRole.INSPECTOR,
                        },
                        {
                          label: "Photographer",
                          value: VendorRole.PHOTOGRAPHER,
                        },
                        {
                          label: "Title company",
                          value: VendorRole.TITLE_COMPANY,
                        },
                      ]}
                    />
                  ) : null} */}

                  <Button
                    style={{
                      backgroundColor: "#FFE500",
                      borderRadius: "40px",
                      color: "black",
                      height: "35px",
                      width: "fit-content",
                      fontWeight: "700",
                      padding: "0px 15px 0px 15px",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      textTransform: "none",
                    }}
                    type="button"
                    // disabled={createUserLoading}
                    onClick={updateUserDetails}
                  >
                    Update User
                  </Button>
                </Stack>
              ) : (
                // Address Form
                <Stack spacing={1}>
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Street
                      </span>
                    }
                    type="text"
                    variant="outlined"
                    required
                    placeholder="Enter Street Address"
                    value={addressLine1}
                    onChange={setAddressLine1}
                  />
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Apt, suite, etc. (optional)
                      </span>
                    }
                    type="text"
                    variant="outlined"
                    required
                    placeholder="Apt, suite, etc."
                    value={addressLine2}
                    onChange={setAddressLine2}
                  />
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        City
                      </span>
                    }
                    type="email"
                    variant="outlined"
                    required
                    placeholder="Enter City Name"
                    value={city}
                    onChange={setCity}
                  />
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        State
                      </span>
                    }
                    type="text"
                    variant="outlined"
                    required
                    placeholder="Enter State Name"
                    value={state}
                    onChange={setState}
                  />
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          // backgroundColor:"red",
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        fontWeight: "600",
                      },
                    }}
                    style={{ marginBottom: "10px" }}
                    label={
                      <span
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Zip Code
                      </span>
                    }
                    type="number"
                    variant="outlined"
                    required
                    placeholder="Enter Zip Code"
                    value={zipCode}
                    onChange={setZipCode}
                  />

                  <Button
                    style={{
                      backgroundColor: "#FFE500",
                      borderRadius: "40px",
                      color: "black",
                      height: "35px",
                      width: "fit-content",
                      fontWeight: "700",
                      padding: "0px 15px 0px 15px",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      textTransform: "none",
                    }}
                    type="button"
                    // disabled={createUserLoading}
                    onClick={updateUserDetails}
                  >
                    Update User
                  </Button>
                </Stack>
              )}
            </form>
          </Box>
        </Drawer>

        {/* Filter Modal */}
        <Modal
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: {
              backgroundColor: "transparent",
              backdropFilter: { xs: "blur(2px)", lg: "blur(0px)" },
            }, // Removes opacity
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: { xs: "50%", lg: `${position?.top}px` },
              left: { xs: "50%", lg: `${position?.left + 50}px` },
              transform: {
                xs: "translate(-50%, -50%)",
                lg: "translate(0%, 0%)",
              },
              width: { xs: "95vw", sm: "88vw", md: "75vw", lg: "57vw" },
              maxWidth: 900,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 3,
            }}
          >
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              marginTop="20px"
              sx={{
                px: 2,
              }}
            >
              <p
                style={{
                  margin: 0,
                  paddingBottom: "20px",
                  fontFamily: "PlusJakartaSans-SemiBold",
                }}
              >
                Filter
              </p>
            </Box>
            <Box sx={{ justifyContent: "center" }}>
              <Box sx={{ maxHeight: "350px", overflowX: "auto", px: 2 }}>
                {filters.map((filter, index) => (
                  <>
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "end",
                        mb: 2,
                        gap: "10px",
                        pb: 2,
                        borderBottom: {
                          xs: filters?.length > 1 ? "1px solid lightgray" : "",
                          sm: "none",
                        },
                      }}
                    >
                      {/* <FormControl>
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Column
                      </InputLabel>
                      <Box sx={{ marginBottom: "15px" }} />
                      <TextField
                        id={`column-${index}`}
                        value={filter.column}
                        onChange={(e) =>
                          handleUpdateFilter(index, "column", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          },
                        }}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      />
                    </FormControl> */}
                      {filters?.length > 1 && (
                        <img
                          src={closeIcon}
                          alt="Close Icon"
                          style={{ cursor: "pointer", marginBottom: "4px" }}
                          onClick={() => handleRemoveFilter(index)}
                        />
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: { xs: "100%", sm: "33.33%" },
                        }}
                      >
                        <InputLabel
                          htmlFor="first-name"
                          sx={{
                            color: "black",
                            fontFamily: "PlusJakartaSans-SemiBold",
                          }}
                        >
                          Column
                        </InputLabel>
                        <FormControl>
                          <Select
                            id={`column-${index}`}
                            value={filter.column}
                            onChange={(e) =>
                              handleUpdateFilter(
                                index,
                                "column",
                                e.target.value
                              )
                            }
                            sx={{
                              borderRadius: "30px",
                              height: "35px",
                              width: "100%",
                              fontSize: "15px",
                              fontFamily: "PlusJakartaSans-Regular",
                            }}
                            IconComponent={() => (
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            )}
                          >
                            {/* {usersTableData?.headers?.map((h) => (
                              <MenuItem key={h.key} value={h.key}>
                                {h.title}
                              </MenuItem>
                            ))} */}

                            <MenuItem value="given_name">Given Name</MenuItem>
                            <MenuItem value="family_name">Family Name</MenuItem>
                            <MenuItem value="email_address">Email</MenuItem>
                            <MenuItem value="address_line_1">
                              Address Line 1
                            </MenuItem>
                            <MenuItem value="address_line_2">
                              Address Line 2
                            </MenuItem>
                            <MenuItem value="city">City</MenuItem>
                            <MenuItem value="state">State</MenuItem>
                            <MenuItem value="zip">Zip</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: { xs: "100%", sm: "33.33%" },
                        }}
                      >
                        <InputLabel
                          id={`operator-label-${index}`}
                          sx={{
                            color: "black",
                            fontFamily: "PlusJakartaSans-SemiBold",
                          }}
                        >
                          Operator
                        </InputLabel>
                        <FormControl>
                          <Select
                            labelId={`operator-label-${index}`}
                            id={`operator-${index}`}
                            value={filter.operator}
                            onChange={(e) =>
                              handleUpdateFilter(
                                index,
                                "operator",
                                e.target.value
                              )
                            }
                            sx={{
                              borderRadius: "30px",
                              height: "35px",
                              width: "100%",
                              fontSize: "15px",
                              fontFamily: "PlusJakartaSans-Regular",
                            }}
                            IconComponent={() => (
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            )}
                          >
                            <MenuItem value="equals">=</MenuItem>
                            <MenuItem value="not equals">!=</MenuItem>
                            <MenuItem value="contain">Contain</MenuItem>
                            <MenuItem value="not contain">
                              {" "}
                              Not Contain
                            </MenuItem>
                            <MenuItem value="start with">Starts With</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      {/* <FormControl>
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: { sm:"-28px"},
                        }}
                      >
                        Operator
                      </InputLabel>
                      <Box sx={{ marginBottom: "15px" }} />
                      <TextField
                        id={`operator-${index}`}
                        value={filter.operator}
                        onChange={(e) =>
                          handleUpdateFilter(index, "operator", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          },
                        }}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      />{" "}
                    </FormControl> */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: { xs: "100%", sm: "33.33%" },
                        }}
                      >
                        <InputLabel
                          htmlFor="first-name"
                          sx={{
                            color: "black",
                            fontFamily: "PlusJakartaSans-SemiBold",
                          }}
                        >
                          Value
                        </InputLabel>
                        <FormControl>
                          <TextField
                            id={`value-${index}`}
                            value={filter.value}
                            onChange={(e) =>
                              handleUpdateFilter(index, "value", e.target.value)
                            }
                            InputProps={{
                              style: {
                                borderRadius: "30px",
                                height: "35px",
                                fontSize: "15px",
                                fontFamily: "PlusJakartaSans-Regular",
                              },
                            }}
                            sx={{ marginRight: "10px" }}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </>
                ))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop="20px"
                sx={{ px: 2, pb: 3 }}
              >
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "#FFE500",
                    textTransform: "none",
                    borderRadius: "30px",
                    color: "black",
                    border: "1px solid #DDDDDD",
                    fontSize: "15px",
                    fontFamily: "PlusJakartaSans-Regular",
                  }}
                  startIcon={<ControlPointIcon />}
                  onClick={handleAddFilter}
                >
                  Add Filter
                </Button>

                <p
                  style={{
                    cursor: "pointer",
                    margin: 0,
                    textDecoration: "underline",
                    fontSize: "15px",
                    color: "black",
                    fontFamily: "PlusJakartaSans-Regular",
                  }}
                  onClick={handleCleanAll}
                >
                  Clean All
                </p>
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* <Text variant="h4" fontWeight={600} marginBottom="20px">
          Create user
        </Text>

        <form onSubmit={handleAddUser}>
          <Stack spacing={2}>
            <InputField
              label="Given name"
              type="text"
              variant="outlined"
              required
              value={givenName}
              onChange={setGivenName}
            />
            <InputField
              label="Family name"
              type="text"
              variant="outlined"
              required
              value={familyName}
              onChange={setFamilyName}
            />
            <InputField
              label="Email"
              type="email"
              variant="outlined"
              required
              value={email}
              onChange={setEmail}
            />
            <CheckboxGroup<UserRole>
              label="Choose user roles"
              onChange={setUserRoles}
              options={[
                {
                  label: "Listing specialist",
                  value: UserRole.LISTING_SPECIALIST,
                },
                {
                  label: "Administrator",
                  value: UserRole.ADMINISTRATOR,
                },
                {
                  label: "Vendor",
                  value: UserRole.VENDOR,
                },
              ]}
              value={userRoles}
            />
            {userRoles.has(UserRole.VENDOR) ? (
              <RadioGroup<VendorRole>
                onChange={setVendorRole}
                value={vendorRole}
                label="Vendor role"
                name="vendor-role"
                options={[
                  {
                    label: "Inspector",
                    value: VendorRole.INSPECTOR,
                  },
                  {
                    label: "Photographer",
                    value: VendorRole.PHOTOGRAPHER,
                  },
                  {
                    label: "Title company",
                    value: VendorRole.TITLE_COMPANY,
                  },
                ]}
              />
            ) : null}
            <Button
              variant="contained"
              type="submit"
              disabled={createUserLoading}
            >
              Create
            </Button>
          </Stack>
        </form> */}
      </Stack>
    </ViewWrapper>
  );
};
