import {
  INITIAL_GET_USERS_PARAMS,
  INITIAL_USERS_TABLE_DATA,
  UserFilter,
} from "./consts";
import { useEffect, useRef, useState } from "react";
import { TableData, TableDataItem, TableDataItemValue } from "types/components";
import { useUserStore } from "store";
import { useGetUsers } from "hooks";
import { UsersInStore } from "types/store";
import { RadioGroup, Table, Text } from "components/common";

interface OwnProps {
  setOpenUpdateUserDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedListing: React.Dispatch<React.SetStateAction<string>>;
}

export const ManageUsersTable: React.FC<OwnProps> = ({
  setOpenUpdateUserDrawer,
  setSelectedListing,
}) => {
  const { getUsers } = useGetUsers();
  const { users } = useUserStore();

  const [usersTableData, setUsersTableData] = useState<TableData>({
    ...INITIAL_USERS_TABLE_DATA,
  });
  const [userFilter, setUserFilter] = useState<UserFilter>(UserFilter.ALL);
  const [check, setCheck] = useState<boolean>(false);
  // const [selectedListing, setSelectedListing] = useState<string>("");

  const handleRowClick = (
    item: TableDataItem,
    setStateFunc: React.Dispatch<React.SetStateAction<string>>,
    setOpenUpdateUserDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setStateFunc(item.id as string);
    setOpenUpdateUserDrawer(true); // Set openDrawer to true
  };
  const mapUsersToTableData = (
    data: UsersInStore,
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data?.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof UsersInStore;
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const fetchData = () => {
    const params = { ...INITIAL_GET_USERS_PARAMS };
    switch (userFilter) {
      case UserFilter.ALL:
        break;
      case UserFilter.ADMINS:
        params.isAdmin = true;
        break;
      case UserFilter.SPECIALISTS:
        params.isListingSpecialist = true;
        break;
      case UserFilter.VENDORS:
        params.isVendor = true;
        break;
      default:
        break;
    }
    getUsers(params);
  };

  useEffect(() => {
    fetchData();
  }, [userFilter, check]);

  useEffect(() => {
    mapUsersToTableData(users, INITIAL_USERS_TABLE_DATA, setUsersTableData);
  }, [users]);

  return (
    <>
      {/* <RadioGroup
        onChange={setUserFilter}
        value={userFilter}
        label="Filter users"
        name="user-filters"
        options={[
          {
            label: "All",
            value: UserFilter.ALL,
          },
          {
            label: "Vendors",
            value: UserFilter.VENDORS,
          },
          {
            label: "Specialists",
            value: UserFilter.SPECIALISTS,
          },
          {
            label: "Admins",
            value: UserFilter.ADMINS,
          },
        ]}
      /> */}

      <Table
        setCheck={setCheck}
        tableData={usersTableData}
        onRowClick={(item) =>
          handleRowClick(item, setSelectedListing, setOpenUpdateUserDrawer)
        }
      />
    </>
  );
};
