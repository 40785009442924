import { HttpService } from "services";
import { UpdateListingColumnFunction } from "types/functions/updateListingColumn";
import { AuthorizationLevel } from "types/http";

/**
 * Create user as a administrator
 *
 * @throws {UserServiceException}
 */
export const updateColumnListing: UpdateListingColumnFunction = async (
  ...args: Parameters<UpdateListingColumnFunction>
) => {
  try {
    const { column_keys } = args[0];
    await HttpService.fetch(
      "/column",
      {
        requestConfig: {
          method: "POST",
          data: {
            column_keys: column_keys,
          },
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return true;
  } catch (error) {
    throw error;
  }
};
